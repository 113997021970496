import { all, takeLatest } from "redux-saga/effects";

import { API_ENDPOINTS } from "@constants";
import { request } from "@utils";
import { userActions } from "./userSlice";

const {
  allUsers,
  allUsersSuccess,
  addUser,
  allPermissions,
  allPermissionsSuccess,
  updateUser,
  addUserPermissions,
  allAdmins,
  allAdminsSuccess,
  updateAdminPassword,
  updateRealtor,
  getSingleRealtor,
  getSingleRealtorSuccess,
} = userActions;

// Get All Users
const getallAdmins = request({
  APIUrl: API_ENDPOINTS.GET.users.all,
  requestType: "get",
  successFunction: allAdminsSuccess,
});

// Get All Permissions
const getAllPermissions = request({
  APIUrl: API_ENDPOINTS.GET.users.allPermissions,
  requestType: "get",
  successFunction: allPermissionsSuccess,
});

// Add Users
const addUserSaga = request({
  APIUrl: API_ENDPOINTS.GET.users.add,
  requestType: "post",
  showNotification: true,
});

// Update Users
const updateUserSaga = request({
  APIUrl: API_ENDPOINTS.GET.users.update,
  requestType: "post",
  showNotification: true,
});

// Add User Permissions
const addPermissionsSaga = request({
  APIUrl: API_ENDPOINTS.GET.users.addPermissions,
  requestType: "post",
  showNotification: true,
});

// Get All Users (Users-Realtors)
const getAllUsers = request({
  APIUrl: API_ENDPOINTS.GET.estate.all,
  requestType: "get",
  successFunction: allUsersSuccess,
});

// Update Admin Password Saga
const updateAdminPasswordSaga = request({
  APIUrl: API_ENDPOINTS.POST.users.update_password,
  requestType: "post",
  showNotification: true,
});

// Update Realtor Saga
const updateRealtorSaga = request({
  APIUrl: API_ENDPOINTS.POST.users.update_realtor,
  requestType: "post",
  showNotification: true,
});

// Get Single Realtor
const getSingleRealtorSaga = request({
  APIUrl: API_ENDPOINTS.GET.users.singleRealator,
  requestType: "get",
  successFunction: getSingleRealtorSuccess,
});

export default function* userSaga() {
  yield all([
    takeLatest(allAdmins.type, getallAdmins),
    takeLatest(allPermissions.type, getAllPermissions),
    takeLatest(addUser.type, addUserSaga),
    takeLatest(updateUser.type, updateUserSaga),
    takeLatest(addUserPermissions.type, addPermissionsSaga),
    takeLatest(allUsers.type, getAllUsers),
    takeLatest(updateAdminPassword.type, updateAdminPasswordSaga),
    takeLatest(updateRealtor.type, updateRealtorSaga),
    takeLatest(getSingleRealtor.type, getSingleRealtorSaga),
  ]);
}
