import { RequestPayloadDto, SuccessPayload } from "@models";
import { RealEstateDto } from "@models/RealEstate";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { CityDto, NeighborhoodDto } from "@store/settings/settingSlice";

export interface OfferDto {
  accept_review_at: string;
  accepted_at: string;
  additional_code: string;
  address: string;
  advertiser_character: string;
  advertiser_email: string;
  advertiser_license_number: string;
  advertiser_mobile: string;
  advertiser_number: string;
  advertiser_side: string;
  app_name: string;
  area_estate_id: number;
  area_estate_range: string;
  available: number;
  bank_id: string;
  bank_name: string;
  bathrooms_number: number;
  bedroom_number: number;
  beneficiary_mobile: string;
  beneficiary_name: string;
  boards_number: number;
  building_number: string;
  cancel_at: string;
  city_id: number;
  city_name: string;
  company_id: string;
  contact_status: string;
  count_active_offer: number;
  count_deleted_offer: number;
  count_expired_offer: number;
  count_offers: number;
  created_at: string;
  deleted_at: string;
  deleted_by_company: string;
  dining_rooms_number: number;
  dir_estate: string;
  dir_estate_id: number;
  elevators_number: number;
  emp_id: string;
  estate_age: number;
  estate_dimensions: string;
  estate_id: number;
  estate_name: string;
  estate_price_id: number;
  estate_price_range: string;
  estate_status: string;
  estate_type_icon: string;
  estate_type_id: number;
  estate_type_name: string;
  estate_use_type: string;
  exclusive_contract_file: string;
  exclusive_marketing: string;
  finishing_type: string;
  first_image: string;
  first_show_date: string;
  floor_number: string;
  full_address: string;
  fund_request_neighborhoods: string;
  group_estate_id: string;
  guarantees: string;
  guard_identity: string;
  guard_mobile: string;
  guard_name: string;
  hide_estate_id: string;
  id: number;
  in_fav: number;
  in_fund_offer: string;
  instrument_date: string;
  instrument_file: string;
  instrument_number: string;
  instrument_status: string;
  instument_number: string;
  interface: string;
  is_checked: string;
  is_close: number;
  is_complete: string;
  is_disputes: string;
  is_from_rent_system: string;
  is_hide: string;
  is_insured: string;
  is_investment: string;
  is_mortgage: string;
  is_obligations: string;
  is_rent: string;
  is_rent_installment: string;
  is_resident: string;
  is_saudi_building_code: string;
  is_send_beneficiary_information: string;
  is_updated_image: number;
  kitchen_number: number;
  lan: number;
  lat: number;
  link: string;
  lounges_number: number;
  meter_price: string;
  neighborhood_id: number;
  neighborhood_name: string;
  note: string;
  obligations_information: string;
  offer_id: number;
  offer_numbers: string;
  operation_type_id: number;
  operation_type_name: string;
  owner_birth_day: string;
  owner_estate_mobile: string;
  owner_estate_name: string;
  owner_management_commission: string;
  owner_management_commission_type: string;
  owner_mobile: string;
  owner_name: string;
  pace_number: string;
  parking_spaces_numbers: number;
  payment_value: string;
  planned_number: string;
  postal_code: string;
  provider_id: number;
  rate: string;
  reason: string;
  rent_installment_price: string;
  rent_price: string;
  rent_type: string;
  request_id: number;
  request_preview_date: string;
  review_at: string;
  rooms_number: number;
  rooms_number_id: string;
  seen_count: string;
  send_offer_type: string;
  show_count: number;
  social_status: string;
  start_at: string;
  state_id: string;
  status: string;
  status_name: string;
  street_name: string;
  street_view: string;
  street_view_id: number;
  street_view_range: string;
  total_area: string;
  total_area_number: string;
  total_price: string;
  total_price_number: string;
  touching_information: string;
  unit_counter: string;
  unit_number: string;
  updated_at: string;
  user_id: number;
  uuid: string;
  video: string;
}

export interface MarketingOffersDto {
  beneficiary_mobile: string;
  beneficiary_name: string;
  created_at: string;
  deleted_at: string;
  estate: RealEstateDto;
  estate_id: number;
  guarantees: string;
  id: number;
  instument_number: string;
  provider: any;
  rate: number;
  request_id: number;
  status: string;
  updated_at: string;
  user_id: number;
}

export interface FundRequestDto {
  area_estate: any;
  area_estate_id: number;
  area_estate_range: string;
  assigned: null;
  assigned_id: null;
  beneficiary_mobile: string;
  beneficiary_name: string;
  city: CityDto;
  city_id: number;
  city_name: string;
  city_name_web: string;
  contact_stages: [];
  count_active_offer: number;
  count_deleted_offer: number;
  count_expired_offer: number;
  count_offers: number;
  created_at: string;
  deleted_at: null;
  dir_estate: string;
  dir_estate_id: number;
  estate_price: any;
  estate_price_id: number;
  estate_price_range: string;
  estate_status: null;
  estate_type: any;
  estate_type_icon: string;
  estate_type_id: string;
  estate_type_name: string;
  estate_type_name_web: string;
  finance_stages: [];
  fund_request_neighborhoods: string;
  id: number;
  is_send_beneficiary_information: string;
  link: string;
  neighborhood: NeighborhoodDto;
  neighborhood_id: null;
  neighborhood_name: string;
  offer_numbers: null;
  offers: [];
  preview_stages: [];
  rooms_number_id: null;
  state_id: number;
  status: string;
  status_name: string;
  street_view: any;
  street_view_id: number;
  street_view_range: string;
  time: string;
  uuid: string;
}

interface initialStateDto {
  offers: SuccessPayload<OfferDto>;
  fund_details?: FundRequestDto;
}

const initialState: initialStateDto = {
  offers: {
    data: [],
    current_page: 1,
    from: 1,
    last_page: 1,
    per_page: 10,
    to: 1,
    total: 0,
    path: "",
  },
};

const fundSlice = createSlice({
  name: "fund",
  initialState,
  reducers: {
    allFundOffers(_state, _action: PayloadAction<RequestPayloadDto>) {},
    allFundOffersSuccess(state, action: PayloadAction<any>) {
      state.offers =
        action.payload.fund_request_offer ||
        action.payload.request_offer ||
        action.payload.app_request ||
        action.payload.fund_request_deleted;
    },

    // Fund Request Details
    fundRequestDetails(_state, _action: PayloadAction<RequestPayloadDto>) {},
    fundRequestDetailsSuccess(state, action: PayloadAction<FundRequestDto>) {
      state.fund_details = action.payload;
    },

    //  Reset Fund Details
    resetFundDetails(state) {
      state.fund_details = undefined;
    },
  },
});

// Fund Actions
export const fundActions = fundSlice.actions;

// Fund Reducer
const fundReducer = fundSlice.reducer;

export default fundReducer;
