import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RequestPayloadDto, SuccessPayload } from "@models";
import { RatingsRequestDto } from "@models/RealEstate";

interface initialStateDto {
  ratings?: SuccessPayload<RatingsRequestDto>;
  single?: RatingsRequestDto;
}

const initialState: initialStateDto = {};

const ratingsSlice = createSlice({
  name: "ratings",
  initialState,
  reducers: {
    // All Ratings
    getRatings(
      _state: initialStateDto,
      _action: PayloadAction<RequestPayloadDto>
    ) {},
    getRatingsSuccess(
      state: initialStateDto,
      action: PayloadAction<SuccessPayload<RatingsRequestDto>>
    ) {
      state.ratings = action.payload;
    },

    // Single Rating
    getRating(
      _state: initialStateDto,
      _action: PayloadAction<RequestPayloadDto>
    ) {},
    getRatingSuccess(
      state: initialStateDto,
      action: PayloadAction<RatingsRequestDto>
    ) {
      state.single = action.payload;
    },

    // Clear Single Rating
    clearRating(state: initialStateDto) {
      state.single = undefined;
    },
    clearRatings(state: initialStateDto) {
      state.ratings = undefined;
    },
  },
});

// Ratings Actions
export const ratingsActions = ratingsSlice.actions;

// Ratings Reducer
const ratingsReducer = ratingsSlice.reducer;

export default ratingsReducer;
