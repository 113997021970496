export enum RequestsEnum {
  login = "auth/login",
  getCities = "get/cities",
  allEstates = "Estates/getEstates",
  allUnacceptedEstates = "Estates/getUnacceptedEstates",
  acceptEstate = "Estates/acceptEstate",
  sendSms = "settings/sendSms",
  allReports = "reports/allReports",
  deleteEstate = "Estates/deleteEstate",
  allUsers = "users/allUsers",
  allAdmins = "users/allAdmins",
  addUser = "users/addUser",
  updateUser = "users/updateUser",
  addPermissions = "users/addUserPermissions",
  getInspections = "inspection/getInspections",
  getAllOrders = "orders/getAll",
  getDashMap = "settings/getDashMap",
  getEstateDetails = "Estates/getEstateDetails",
  allOffers = "fund/allFundOffers",
  underReview = "fund/underReview",
  updateEstate = "Estates/updateEstate",
  updateAdminPassword = "users/updateAdminPassword",
  getRatings = "ratings/getRatings",
  singleRating = "ratings/getRating",
  getContacts = "contact/getContacts",
  fundRequestDetails = "fund/fundRequestDetails",
  updateRealtor = "users/updateRealtor",
  addPlan = "plans/addPlan",
  updatePlan = "plans/updatePlan",
  deletePlan = "plans/deletePlan",
}

export interface RequestPayloadDto {
  data?: FormData;
  callback?: any;
  id?: number;
  params?: object;
  finalCallback?: any;
}

export interface LinkResponseDto {
  first: string;
  last: string;
  prev: unknown;
  next: unknown;
}

export interface MetaResponseDto {
  current_page: number;
  from: number;
  last_page: number;
  links: LinkResponseDto[];
  path: string;
  per_page: string;
  to: number;
  total: number;
}

export interface SuccessPayload<T> {
  data?: T[];
  links?: LinkResponseDto;
  current_page: number;
  from: number;
  last_page: number;
  path: string;
  per_page: number;
  to: number;
  total: number;
  meta?: MetaResponseDto;
}
