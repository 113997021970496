/* eslint-disable import/no-anonymous-default-export */
import { Loader } from "@components/ui";

import styles from "./index.module.scss";

export default () => {
  return (
    <div className={styles.loader}>
      <Loader />
    </div>
  );
};
