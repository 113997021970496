import i18n from "i18next";

export const Localization = {
  isRTL: (): boolean | undefined => {
    if (i18n.dir(i18n.language) === "ltr") return false;
    if (i18n.dir(i18n.language) === "rtl") return true;

    return;
  },

  getCurrentLanguage() {
    return i18n.language;
  },

  getLanguages() {
    return i18n.languages;
  },

  getMomentLocals: (): any => {
    return {
      relativeTime: Localization.relativeTimeOfCurrentLanguage(),
      meridiem: function (hour: number) {
        if (hour < 12) return i18n.t("AM");
        else return i18n.t("PM");
      },
    };
  },

  relativeTimeOfCurrentLanguage: (): any => {
    if (i18n.language === "ar")
      return {
        future: "خلال %s",
        past: "منذ %s",
        s: "ثوان قليلة",
        ss: "%d ثوان",
        m: "دقيقة",
        mm: "%d دقائق",
        h: "ساعة",
        hh: "%d ساعات",
        d: "يوم",
        dd: "%d أيام",
        M: "شهر",
        MM: "%d أشهر",
        y: "سنة",
        yy: "%d سنوات",
      };
    else
      return {
        future: "in %s",
        past: "%s ago",
        s: "a few seconds",
        ss: "%d seconds",
        m: "a minute",
        mm: "%d minutes",
        h: "an hour",
        hh: "%d hours",
        d: "a day",
        dd: "%d days",
        M: "a month",
        MM: "%d months",
        y: "a year",
        yy: "%d years",
      };
  },
};
