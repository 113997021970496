import { FC } from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useAuth } from "@hooks";
import { NonAuthRoutes } from "@models";

interface RequireAuthProps {
  allowedRoles: string[];
}

const RequireAuth: FC<RequireAuthProps> = ({ allowedRoles = [] }) => {
  const auth = useAuth();
  const location = useLocation();

  return auth?.role && allowedRoles?.includes(auth?.role) ? (
    <Outlet />
  ) : auth && auth!.api_token ? (
    <Navigate
      to={NonAuthRoutes.UNAUTHORIZED}
      state={{ from: location }}
      replace
    />
  ) : (
    <Navigate to={NonAuthRoutes.LOGIN} state={{ from: location }} replace />
  );
};

export default RequireAuth;
