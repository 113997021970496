import { RequestPayloadDto } from "@models";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface estateTypesDto {
  created_at: string;
  deleted_at: string;
  icon: string;
  id: number;
  name: string;
  name_ar: string;
  name_en: string;
  status: string;
  updated_at: string;
}

interface StateDto {
  id: estateTypesDto["id"];
  value: estateTypesDto["name"];
}

export interface CityDto {
  city_id: number;
  count_app_estate: number;
  count_app_request: number;
  count_fund_request: number;
  created_at: string;
  id: number;
  latitude: number;
  longitude: number;
  name: string;
  name_ar: string;
  name_en: string;
  serial_city: number;
  state_code: string;
  state_id: number;
  status: number;
  updated_at: string;
}

export interface NeighborhoodDto {
  city_id: number;
  created_at: string;
  estate_counter: number;
  id: number;
  lan: number;
  lat: number;
  name: string;
  name_ar: string;
  name_en: string;
  neighborhood_serial: number;
  request_app_counter: number;
  request_fund_counter: number;
  search_name: string;
  state_id: number;
  status: string;
  updated_at: string;
}

interface AreaDto {
  area_from: number;
  area_range: string;
  area_to: 250;
  created_at: string;
  deleted_at: string;
  id: number;
  status: string;
  updated_at: string;
}

interface PriceDto {
  created_at: string;
  deleted_at: string;
  estate_price_from: string;
  estate_price_range: string;
  estate_price_to: string;
  id: number;
  status: string;
  updated_at: string;
}

interface ServiceDto {
  id: number;
  name_ar: string;
  name_en: string;
  status: string;
  deleted_at: string;
  created_at: string;
  updated_at: string;
  name: string;
}

interface MemberDto {
  id: number;
  name_ar: string;
  name_en: string;
  icon: string;
  status: string;
  deleted_at: string;
  created_at: string;
  updated_at: string;
  name: string;
}

interface ExperienceDto {
  id: number;
  name_ar: string;
  name_en: string;
  icon: string;
  status: string;
  deleted_at: string;
  created_at: string;
  updated_at: string;
  name: string;
}

interface CourseDto {
  id: number;
  name_ar: string;
  name_en: string;
  icon: string;
  status: string;
  deleted_at: string;
  created_at: string;
  updated_at: string;
  name: string;
}

interface initialStateDto {
  estateTypes?: Array<estateTypesDto>;
  states?: Array<StateDto>;
  cities?: Array<CityDto>;
  neighborhoods?: Array<NeighborhoodDto>;
  price?: Array<PriceDto>;
  area?: Array<AreaDto>;
  employees?: any;
  comforts?: any;
  operation_types?: any;
  places?: {
    الشرقية: {
      requests: 0;
      offer: 0;
      providers: 0;
    };
    الباحة: {
      requests: 0;
      offer: 0;
      providers: 0;
    };
    جازان: {
      requests: 0;
      offer: 0;
      providers: 0;
    };
    حائل: {
      requests: 0;
      offer: 0;
      providers: 0;
    };
    القصيم: {
      requests: 0;
      offer: 0;
      providers: 0;
    };
    الرياض: {
      requests: 0;
      offer: 0;
      providers: 0;
    };
    نجران: {
      requests: 0;
      offer: 0;
      providers: 0;
    };
    تبوك: {
      requests: 0;
      offer: 0;
      providers: 0;
    };
    "المدينة المنورة": {
      requests: 0;
      offer: 0;
      providers: 0;
    };
    "مكه المكرمة": {
      requests: 0;
      offer: 0;
      providers: 0;
    };
    عسير: {
      requests: 0;
      offer: 0;
      providers: 0;
    };
    الجوف: {
      requests: 0;
      offer: 0;
      providers: 0;
    };
    "الحدود الشمالية": {
      requests: 0;
      offer: 0;
      providers: 0;
    };
  };
  serviceTypes?: ServiceDto[];
  memberTypes?: MemberDto[];
  experienceTypes?: ExperienceDto[];
  courseTypes?: CourseDto[];
}

const initialState: initialStateDto = {};

const settingSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    // Send Sms Reducer
    sendSms(
      _state: initialStateDto,
      _action: PayloadAction<RequestPayloadDto>
    ) {},
    // Get Estate Types
    estateType() {},
    estateTypeSuccess(
      state: initialStateDto,
      action: PayloadAction<Array<estateTypesDto>>
    ) {
      state.estateTypes = action.payload;
    },
    // Get state
    getState(
      _state: initialStateDto,
      _action: PayloadAction<RequestPayloadDto>
    ) {},
    getStateSuccess(
      state: initialStateDto,
      action: PayloadAction<Array<StateDto>>
    ) {
      state.states = action.payload;
    },
    // Get Cities
    getCities(
      _state: initialStateDto,
      _action: PayloadAction<RequestPayloadDto>
    ) {},
    getCitiesSuccess(
      state: initialStateDto,
      action: PayloadAction<Array<CityDto>>
    ) {
      state.cities = action.payload;
    },
    // Get Neighborhoods
    getNeighborhoods(
      _state: initialStateDto,
      _action: PayloadAction<RequestPayloadDto>
    ) {},
    getNeighborhoodsSuccess(
      state: initialStateDto,
      action: PayloadAction<Array<NeighborhoodDto>>
    ) {
      state.neighborhoods = action.payload;
    },
    // Get Areas
    getAreas() {},
    getAreasSuccess(
      state: initialStateDto,
      action: PayloadAction<Array<AreaDto>>
    ) {
      state.area = action.payload;
    },
    // Get Price
    getPrice() {},
    getPriceSuccess(
      state: initialStateDto,
      action: PayloadAction<Array<PriceDto>>
    ) {
      state.price = action.payload;
    },
    // get Dash Map
    getDashMap() {},
    getDashMapSuccess(state: initialStateDto, action: PayloadAction<any>) {
      state.places = action.payload;
    },
    // get Dash Map
    getEmployees() {},
    getEmployeesSuccess(state: initialStateDto, action: PayloadAction<any>) {
      state.employees = action.payload;
    },

    // Get Comforts
    getComforts() {},
    getComfortsSuccess(state: initialStateDto, action: PayloadAction<any>) {
      state.comforts = action.payload;
    },

    // Get Operation Types
    getOperationTypes() {},
    getOperationTypesSuccess(
      state: initialStateDto,
      action: PayloadAction<any>
    ) {
      state.operation_types = action.payload;
    },

    // Get Service Types
    getServiceTypes() {},
    getServiceTypesSuccess(
      state: initialStateDto,
      action: PayloadAction<ServiceDto[]>
    ) {
      state.serviceTypes = action.payload;
    },

    // Get Member Types
    getMemberTypes() {},
    getMemberTypesSuccess(
      state: initialStateDto,
      action: PayloadAction<MemberDto[]>
    ) {
      state.memberTypes = action.payload;
    },

    // Get Experience Types
    getExperienceTypes() {},
    getExperienceTypesSuccess(
      state: initialStateDto,
      action: PayloadAction<ExperienceDto[]>
    ) {
      state.experienceTypes = action.payload;
    },

    // Get Course Types
    getCourseTypes() {},
    getCourseTypesSuccess(
      state: initialStateDto,
      action: PayloadAction<CourseDto[]>
    ) {
      state.courseTypes = action.payload;
    },
  },
});

// Settings Action
export const settingsActions = settingSlice.actions;

const settingsReducer = settingSlice.reducer;

export default settingsReducer;
