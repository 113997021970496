import { RequestPayloadDto, SuccessPayload } from "@models";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface FinancingDto {
  employer_name: string;
  employer_type: string;
  id: number;
  lead_id: string;
  personal_id_number: string;
  personal_mobile_number: string;
  personal_monthly_net_salary: string;
  personal_name: string;
  rea_estate_property_age: string;
  real_estate_product_type: string;
  real_estate_property_information: string;
  real_estate_property_price: string;
  request_id: string;
  status_code: string;
  status_description: string;
  user_id: number;
  user_name: string;
}

interface initialStateDto {
  requests?: SuccessPayload<FinancingDto>;
}

const initialState: initialStateDto = {
  requests: {} as SuccessPayload<FinancingDto>,
};

const financingSlice = createSlice({
  name: "financing",
  initialState,
  reducers: {
    getAll(
      _state: initialStateDto,
      _action: PayloadAction<RequestPayloadDto>
    ) {},
    getAllSuccess(state: initialStateDto, action: PayloadAction<any>) {
      state.requests = action.payload;
    },
  },
});

// Financing Actions
export const financingActions = financingSlice.actions;

// Financing Reducer
const financingReducer = financingSlice.reducer;

export default financingReducer;
