import { FC } from "react";
import { Button, Input } from "antd";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";

import styles from "./styles.module.scss";

interface CounterProps {
  value: number;
  setValue: any;
  formKey: string;
}

const Counter: FC<CounterProps> = ({ value = 0, setValue, formKey }) => {
  return (
    <div className={`${styles.qtyInput} ${value > 0 && "bg-primary"}`}>
      <Button
        onClick={() =>
          setValue({
            [formKey]: isNaN(value) ? 1 : value + 1,
          })
        }
      >
        <PlusOutlined />
      </Button>
      <Input value={value} />
      <Button
        onClick={() =>
          setValue({
            [formKey]: !isNaN(value) && value > 0 ? value - 1 : 0,
          })
        }
      >
        <MinusOutlined />
      </Button>
    </div>
  );
};

export default Counter;
