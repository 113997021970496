import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { PaginationProps } from "antd";
import { useTranslation } from "react-i18next";

interface IProps {
  searchProperties?: string[];
}

type IParams = {
  fields?: any;
  deleteFields?: string[];
};

type HandleSearch = (params: IParams) => void;

const useAppParams = ({ searchProperties }: IProps) => {
  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();
  const [UrlParams, setUrlParams] = useState<any>({});

  useEffect(() => {
    let params = {};

    if (searchParams) {
      searchParams.forEach((value, key) => {
        params = {
          ...params,
          [key]: value,
        };
      });
    }

    if (searchProperties) {
      searchProperties.forEach((key) => {
        if (!searchParams.has(key)) {
          params = {
            ...params,
            [key]: "",
          };
        }
      });
    }

    setUrlParams(params);
  }, [searchParams, searchProperties]);

  // @desc handle search params in url and update url
  const handleSearch: HandleSearch = (params: IParams) => {
    const { fields, deleteFields } = params;
    let newSearchParams = new URLSearchParams(searchParams);

    if (fields) {
      Object.keys(fields).forEach((key) => {
        if (fields[key]) {
          newSearchParams.set(key, fields[key]);
        } else {
          newSearchParams.delete(key);
        }
      });
    }

    if (deleteFields) {
      deleteFields.forEach((key) => {
        newSearchParams.delete(key);
      });
    }

    if (searchProperties) {
      searchProperties.forEach((key) => {
        if (!newSearchParams.has(key)) {
          newSearchParams.set(key, "");
        }
      });
    }

    setSearchParams(newSearchParams);
  };

  // @desc handle table pagination
  const handleTablePagination = ({ total }: any): PaginationProps => {
    return {
      total,
      pageSize: parseInt(UrlParams.page_number) || 10,
      current: parseInt(UrlParams.page) || 1,
      showSizeChanger: true,
      hideOnSinglePage: false,
      showTotal: (total, range) => `${range[1]} ${t("Of")} ${total}`,
      onChange: (page, pageSize) =>
        handleSearch({
          fields: {
            page,
            page_number: pageSize,
          },
        }),
    };
  };

  // @desc handle table change
  const handleTableChange = (
    pagination: PaginationProps,
    _filters: any,
    sorter: any
  ) => {
    const { current, pageSize } = pagination;
    const { field, order } = sorter;

    let OrderBy: string = "";
    let deleteFields: string[] = [];

    switch (order) {
      case "ascend":
        OrderBy = "ASC";
        break;
      case "descend":
        OrderBy = "DESC";
        break;
      default:
        OrderBy = "";
        break;
    }

    if (!OrderBy) {
      deleteFields = ["sort"];
    }

    handleSearch({
      fields: {
        page: current,
        limit: pageSize,
        sort: `${field} ${OrderBy}`,
      },
      deleteFields,
    });

    window.scrollTo(0, 0);
  };

  return {
    UrlParams,
    handleSearch,
    handleTablePagination,
    handleTableChange,
  };
};

export default useAppParams;
