import { t } from "i18next";

export const getErrorType = (type: string) => {
  switch (type) {
    case "error_data":
      return t("DataIsIncorrect");

    case "contact_error":
      return t("CommunicationProblem");

    case "error_image":
      return t("UnitIsNotAvailable");

    case "other":
      return t("Other");

    default:
      return t("UnknownError");
  }
};
