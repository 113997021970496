import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RequestPayloadDto, SuccessPayload } from "@models";

export interface ContactDto {
  id: number;
  name: string;
  email: string;
  mobile: string;
  msg: string;
  deleted_at: string;
  created_at: string;
  updated_at: string;
}

interface initialStateDto {
  contacts?: SuccessPayload<ContactDto>;
}

const initialState: initialStateDto = {};

const contactSlice = createSlice({
  name: "contact",
  initialState,
  reducers: {
    // All Contacts
    getContacts(
      _state: initialStateDto,
      _action: PayloadAction<RequestPayloadDto>
    ) {},
    getContactsSuccess(
      state: initialStateDto,
      action: PayloadAction<SuccessPayload<ContactDto>>
    ) {
      state.contacts = action.payload;
    },

    // Clear State
    clearContact(state: initialStateDto) {
      state.contacts = undefined;
    },
  },
});

// Contact Actions
export const contactActions = contactSlice.actions;

// Contact Reducer
const contactReducer = contactSlice.reducer;

export default contactReducer;
