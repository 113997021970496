import { all, takeLatest } from "redux-saga/effects";

import { request } from "@utils";
import { API_ENDPOINTS } from "@constants";
import { financingActions } from "./financingSlice";

const getAllRequestsSaga = request({
  APIUrl: API_ENDPOINTS.GET.requests.financing_requests,
  requestType: "get",
  successFunction: financingActions.getAllSuccess,
});

export default function* orderSaga() {
  yield all([takeLatest(financingActions.getAll.type, getAllRequestsSaga)]);
}
