import { FC } from "react";
import { Button } from "antd";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
import { useTypedSelector, useAppDispatch } from "@hooks";
import s from "./index.module.scss";
import { appActions } from "@store/loading/appSlice";

const Hamburger: FC = () => {
  const dispatch = useAppDispatch();
  const { sidebarCollapsed } = useTypedSelector((state) => state.app);

  // toggle side menu
  const toggleSideMenu = () => {
    dispatch(appActions.toggleSideBar());
  };
  return (
    <div className={s.hamburger} onClick={toggleSideMenu}>
      {!sidebarCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
    </div>
  );
};

export default Hamburger;
