import { all, takeLatest } from "redux-saga/effects";

import { API_ENDPOINTS } from "@constants";
import { request } from "@utils";
import { reportActions } from "./reportSlice";

const { allReports, allReportsSuccess } = reportActions;

// Get All Reports
const getAllReports = request({
  APIUrl: API_ENDPOINTS.GET.reports.all,
  requestType: "get",
  successFunction: allReportsSuccess,
});

export default function* reportSaga() {
  yield all([takeLatest(allReports.type, getAllReports)]);
}
