import { lazy, Suspense, useEffect } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { ConfigProvider } from "antd";
import arEG from "antd/lib/locale/ar_EG";
import enUS from "antd/lib/locale/en_US";
import moment from "moment";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import { Loader } from "@components/ui";
import Layout from "@components/Layout";
import { Localization, TimingHelper, theme } from "@utils";
import { ROUTE, NonAuthRoutes, RouteDto } from "@models";
import { RequireAuth } from "@components/common";
import { useAuth } from "@hooks";
import { routesList } from "@constants";
import { helmetConfig } from "@lib";
import i18n from "./i18n";

import "antd/dist/reset.css";
import "./styles/main.scss";

const NotFound = lazy(() => import("@pages/NotFound"));
const Login = lazy(() => import("@pages/User/Login"));
const UnAuthorized = lazy(() => import("@pages/UnAuthorized"));

function App() {
  const { t } = useTranslation();
  const user = useAuth();
  const currentLanguage = localStorage.getItem("i18nextLng") || "ar";

  useEffect(() => {
    TimingHelper.initTiming();
    moment.locale(Localization.getCurrentLanguage());
  }, []);

  useEffect(() => {
    document.body.dir = currentLanguage === "en" ? "ltr" : "rtl";
    document.documentElement.setAttribute("lang", currentLanguage);
  }, [currentLanguage]);

  // Add Sentry to catch errors
  useEffect(() => {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      integrations: [new Integrations.BrowserTracing()],
      tracesSampleRate: 1.0,

      // Add release version
      release: process.env.REACT_APP_VERSION,

      // Add environment
      environment: process.env.REACT_APP_ENVIRONMENT,

      beforeSend(event, hint) {
        // Check if it is an exception, and if so, show the report dialog
        if (event.exception) {
          Sentry.showReportDialog({
            poweredBy: false,
            eventId: event.event_id,
            title: t("Sorry,SomethingWentWrong"),
            subtitle: t("AnErrorReportHasBeenSentToTheDevelopmentTeam"),
            subtitle2: t("PleaseDescribeWhatYouWereDoingWhenThisErrorOccurred"),
            labelName: t("YourName"),
            labelEmail: t("YourEmail"),
            labelComments: t("Comments"),
            labelClose: t("Close"),
            labelSubmit: t("Submit"),
            errorGeneric: t("AnErrorHasOccurred"),
            errorFormEntry: t("PleaseEnterAValidValueInTheField"),
            lang: Localization.getCurrentLanguage(),
            successMessage: t("ThankYouForYourFeedback"),
          });
        }
        return event;
      },
    });

    // Set user to Sentry
    Sentry.setUser({
      ...user,
    });

    Sentry.setTag("version", process.env.REACT_APP_VERSION);
  }, [user]);

  return (
    <Sentry.ErrorBoundary>
      <div>
        <ConfigProvider
          locale={i18n.language === "en" ? enUS : arEG}
          direction={i18n.dir(i18n.language)}
          theme={theme}
        >
          <Helmet {...helmetConfig.config.APP} />
          <Suspense fallback={<Loader />}>
            <Routes>
              <Route path={ROUTE.HOME} element={<Layout />}>
                {/* private Routes */}
                {routesList.map((route: RouteDto) => (
                  <Route
                    key={route.id}
                    element={<RequireAuth allowedRoles={route.roles} />}
                  >
                    <Route path={route.path} element={route.component} />
                  </Route>
                ))}
                {/* public Routes */}
                <Route
                  path={NonAuthRoutes.NOT_FOUND}
                  element={
                    user?.api_token ? (
                      <NotFound />
                    ) : (
                      <Navigate to={NonAuthRoutes.LOGIN} />
                    )
                  }
                />
                <Route
                  path={NonAuthRoutes.UNAUTHORIZED}
                  element={
                    user?.api_token ? (
                      <UnAuthorized />
                    ) : (
                      <Navigate to={NonAuthRoutes.LOGIN} />
                    )
                  }
                />
              </Route>
              <Route path={NonAuthRoutes.LOGIN} element={<Login />} />
            </Routes>
          </Suspense>
        </ConfigProvider>
      </div>
    </Sentry.ErrorBoundary>
  );
}

export default App;
