import { FC, useState, useCallback, memo } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  GoogleMapProps,
} from "@react-google-maps/api";

// Google Map Marker
export interface GoogleMapMarkerProps {
  lat: number;
  lng: number;
  title: string;
  onClick?: (e: any) => void;
}

const GoogleMapMarker: FC<GoogleMapMarkerProps> = ({ lat, lng, title }) => {
  return (
    <Marker
      position={{
        lat,
        lng,
      }}
      title={title}
      animation={window.google.maps.Animation.DROP}
    />
  );
};

interface GoogleMapContainerProps extends GoogleMapProps {}

const GoogleMapContainer: FC<GoogleMapContainerProps> = (props) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "",
  });

  const [map, setMap] = useState(null);

  const onLoad = useCallback(function callback(map: any) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    const bounds = new window.google.maps.LatLngBounds(props?.center);
    map.fitBounds(bounds);

    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback(map: any) {
    setMap(null);
  }, []);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={{
        height: "400px",
        width: "100%",
      }}
      onLoad={onLoad}
      options={{
        disableDefaultUI: true,
        zoomControl: true,
        fullscreenControl: true,
      }}
      center={{
        lat: 24.774265,
        lng: 46.738586,
      }}
      onUnmount={onUnmount}
      {...props}
    >
      {/* Child components, such as markers, info windows, etc. */}
      <GoogleMapMarker
        lat={Number(props?.center?.lat)}
        lng={Number(props?.center?.lng)}
        title="Sudia Arabia"
        onClick={(e) => {
          console.log("🚀 ~ file: index.tsx:81 ~ e", e);
        }}
      />
      <></>
    </GoogleMap>
  ) : (
    <></>
  );
};

export default memo(GoogleMapContainer);
