/* eslint-disable import/no-anonymous-default-export */
import React, { useState, useEffect } from "react";
import { Upload, message, Modal } from "antd";
import { CloudUploadOutlined } from "@ant-design/icons";
import { UploadFile } from "antd/lib/upload/interface";
import { useTranslation } from "react-i18next";

import styles from "./styles.module.scss";

export interface UploadFileProps {
  onSuccess?: (fileName: string) => void;
  onRemove?: () => void;
  defaultFileList?: Array<any>;
  setValue?: any;
  value?: Array<any>;
  isVideo?: boolean;
  multiple?: boolean;
  maxCount?: number;
  files?: Array<any>;
}
// const CustomUploadFile: React.FC<UploadFileProps> =
export default ({
  onSuccess,
  onRemove,
  defaultFileList,
  setValue,
  value,
  isVideo = false,
  multiple,
  maxCount,
  files,
}: UploadFileProps) => {
  const { t } = useTranslation();

  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [isUploaded, setIsUploaded] = useState<boolean>(false);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");

  const handleCancel = () => setPreviewVisible(false);

  useEffect(() => {
    if (defaultFileList && defaultFileList!.length > 0) {
      setIsUploaded(true);
    }
  }, []);

  let fileList: Array<UploadFile> = [];
  const checkFileType = (file: any): boolean => {
    return (
      file.type === "video/mp4" ||
      file.type === "image/png" ||
      file.type === "image/jpg" ||
      file.type === "image/png" ||
      file.type === "image/jpeg"
    );
  };

  const isValidFileSize = (file: any) => {
    const fileType = file.type && file.type.split("/")[0];
    return fileType === "video"
      ? file.size / 1024 / 1024 < 50
      : file.size / 1024 / 1024 < 5;
  };

  const validateFile = (file: any) => {
    const fileType = file.type && file.type.split("/")[0];
    const isValidFileType: boolean = checkFileType(file);
    if (!isValidFileType) {
      message.error(`${t("FileTypeNotSupported")}`);
    }
    const checkSize = isValidFileSize(file);
    if (!checkSize && fileType === "video")
      message.error(`${t("videoSizeShouldBeLessThan50MB")}`);
    if (!checkSize && fileType === "image")
      message.error(`${t("ImageSizeShouldBeLessThan5MB")}`);
    return (isValidFileType && checkSize) || Upload.LIST_IGNORE;
  };

  const handleBeforeUpload = (file: any): any => validateFile(file);

  const uploadProps = {
    accept: isVideo
      ? `.MP4, 3g2,
    3gp,
    aaf,
    asf,
    avchd,
    avi,
    drc,
    flv,
    m2v,
    m3u8,
    m4p,
    m4v,
    mkv,
    mng,
    mov,
    mp2,
    mp4,
    MP4,
    mpe,
    mpeg,
    mpg,
    mpv,
    mxf,
    nsv,
    ogg,
    ogv,
    qt,
    rm,
    rmvb,
    roq,
    svi,
    vob,
    webm,
    wmv,
    yuv`
      : "jpg,jpeg,png",
    multiple,
    maxCount,
    beforeUpload: handleBeforeUpload,
  };

  const handleFileListChanges = () => {
    // Read from response and show file link
    setValue(fileList);

    fileList = fileList.map((file) => {
      if (file.response && file.response.result && file.response.result.url) {
        // Component will show file.url as link
        file.url = file.response.result.url;
      }
      return file;
    });
    // this.forceUpdate();
  };
  const onFileChange = (info: any) => {
    const fileList = info?.fileList?.map((item: any) => item.originFileObj);
    setValue(fileList);
    if (info.file?.status === "uploading") {
      setIsUploading(true);
      return;
    }
    handleFileListChanges();

    if (info.file.status === "removed") {
      onRemove && onRemove();
    }
    if (info.file.status === "done") {
      message.success(`${info.file.name} ${t("FileUploadedSuccessfully")}`);
    } else if (info.file.status === "error") {
      info.file.status = "done";
    }

    setValue(fileList);

    return fileList;
  };
  const getBase64 = (file: any) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => resolve(reader.result);

      reader.onerror = (error) => reject(error);
    });

  const handlePreview = async (file: any) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  return (
    <>
      <Upload.Dragger
        className={`${styles.custom_upload}`}
        {...uploadProps}
        accept={isVideo ? "video/*" : "image/*"}
        listType="picture-card"
        defaultFileList={files}
        onChange={onFileChange}
        onPreview={handlePreview}
        showUploadList={{ showRemoveIcon: false }}
      >
        <p className="ant-upload-drag-icon">
          <CloudUploadOutlined />
        </p>
        <p className="ant-upload-text">
          {isVideo
            ? t("DragorclickheretodownloadtheVideo")
            : t("Dragorclickheretodownloadtheimage")}
        </p>
      </Upload.Dragger>
      <Modal
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        {isVideo ? (
          <video controls autoPlay={false} width={450} height={450}>
            <source src={String(previewImage)} />
          </video>
        ) : (
          <img alt="example" style={{ width: "100%" }} src={previewImage} />
        )}
      </Modal>
    </>
  );
};

// export default CustomUploadFile;
