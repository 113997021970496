import { Modal } from "antd";
import { t } from "i18next";
import { AxiosResponse } from "axios";
import {
  Effect,
  StrictEffect,
  takeLatest,
  call,
  all,
  delay,
  put,
} from "redux-saga/effects";

import { CookiesHelper } from "@utils";
import { loadingActions } from "@store/loading/loadingSlice";
import axiosClient from "@constants/axiosClient";
import { API_ENDPOINTS } from "@constants";
import { RequestPayloadDto } from "@models";
import { authActions } from "./authSlice";

const { login, loginSuccess, logout } = authActions;

function* handleLoginSaga({
  type,
  payload,
}: Effect<string, RequestPayloadDto>) {
  try {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: true })
    );

    const res: AxiosResponse = yield call(
      axiosClient.post,
      API_ENDPOINTS.POST.auth.login,
      payload.data
    );

    yield put(
      loginSuccess({
        ...res,
      })
    );

    const user = {
      id: res.data.id,
      logo: res.data.logo,
      user_name: res.data.user_name,
      name: res.data.name,
      email: res.data.email,
      mobile: res.data.mobile,
      api_token: res.data.api_token,
      role: "admin",
    };

    if ((payload.data as FormData).get("rememberMe")) {
      CookiesHelper.set("user", JSON.stringify(user), {
        expires: 365,
      });
    } else {
      CookiesHelper.set("user", JSON.stringify(user));
    }

    yield delay(500);

    payload?.callback?.();
  } catch (error: any) {
    Modal.error({
      title: `${t("SomethingWentWrong")}`,
      content: (error as Error)?.message,
    });
  } finally {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: false })
    );
  }
}

function* handleLogout() {
  yield delay(500);
  CookiesHelper.clearItem("user");
}

export default function* authSaga(): Generator<StrictEffect> {
  yield all([
    takeLatest(login.type, handleLoginSaga),
    takeLatest(logout.type, handleLogout),
  ]);
}
