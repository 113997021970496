import { FC } from "react";
import { Col, Form, Radio, Row } from "antd";
import { useTranslation } from "react-i18next";

import styles from "./styles.module.scss";

interface YesOrNoQuestionProps {
  question: string;
  formKey: string;
}

const YesOrNoQuestion: FC<YesOrNoQuestionProps> = ({ question, formKey }) => {
  const { t } = useTranslation();

  return (
    <Row justify="space-between" align="middle">
      <Col>
        <h4>{question}</h4>
      </Col>
      <Col>
        <Form.Item className="label-block answer-group" name={formKey}>
          <Radio.Group buttonStyle="solid" className={styles.radio}>
            <Radio.Button value={"1"}>{t("Yes")}</Radio.Button>
            <Radio.Button value={"0"}>{t("No")}</Radio.Button>
          </Radio.Group>
        </Form.Item>
      </Col>
    </Row>
  );
};

export default YesOrNoQuestion;
