import { all, takeLatest } from "redux-saga/effects";

import { request } from "@utils";
import { API_ENDPOINTS } from "@constants";
import { fundActions } from "./fundSlice";

const {
  allFundOffers,
  allFundOffersSuccess,
  fundRequestDetails,
  fundRequestDetailsSuccess,
} = fundActions;

// Get All Fund Requests
const getAllFundSaga = request({
  APIUrl: API_ENDPOINTS.GET.estate.all,
  requestType: "get",
  successFunction: allFundOffersSuccess,
});

// Get Fund Request Details By ID
const getFundDetailsSaga = request({
  APIUrl: API_ENDPOINTS.GET.requests.fund_request_details,
  requestType: "get",
  successFunction: fundRequestDetailsSuccess,
});

export default function* fundSaga() {
  yield all([
    takeLatest(allFundOffers.type, getAllFundSaga),
    takeLatest(fundRequestDetails.type, getFundDetailsSaga),
  ]);
}
