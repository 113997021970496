import { all, takeLatest } from "redux-saga/effects";

import { API_ENDPOINTS } from "@constants";
import { request } from "@utils";
import { ratingsActions } from "./ratingSlice";

const { getRatings, getRatingsSuccess, getRating, getRatingSuccess } =
  ratingsActions;

// Get All Ratings
const getRatingsSaga = request({
  APIUrl: API_ENDPOINTS.GET.requests.allRatings,
  requestType: "get",
  successFunction: getRatingsSuccess,
});

// Get Single Rating
const getRatingSaga = request({
  APIUrl: API_ENDPOINTS.GET.requests.singleRating,
  requestType: "get",
  successFunction: getRatingSuccess,
});

export default function* ratingsSaga() {
  yield all([
    takeLatest(getRatings.type, getRatingsSaga),
    takeLatest(getRating.type, getRatingSaga),
  ]);
}
