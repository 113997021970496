import { FC, Suspense } from "react";
import { Layout } from "antd";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import { ROUTE } from "@models";
import { Loader } from "@components/ui";
import s from "./index.module.scss";

const { Content } = Layout;

const LayoutContent: FC = () => {
  const location = useLocation();
  const { pathname } = location;

  if (pathname === ROUTE.HOME) {
    return <Navigate to={ROUTE.DASHBOARD} />;
  }
  return (
    <div className={s.content}>
      <Content style={{ height: "calc(100% - 100px)" }}>
        <TransitionGroup>
          <CSSTransition
            key={pathname}
            timeout={500}
            classNames="fade"
            exit={false}
          >
            <Suspense fallback={<Loader />}>
              <Outlet />
            </Suspense>
          </CSSTransition>
        </TransitionGroup>
      </Content>
    </div>
  );
};

export default LayoutContent;
