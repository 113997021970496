export const API_ENDPOINTS = {
  BASE: process.env.REACT_APP_BASE_URL,
  GET: {
    settings: {
      estateType: `estate/type`,
      state: `state`,
      cities: `cities`,
      neighborhood: (id: number) => `neighborhood/${id}`,
      area: `estate/area`,
      price: `estate/price`,
      getDashMap: `getDashMap`,
      employees: `fund/clickup/get/emp`,
      courseTypes: `course/type`,
      experienceTypes: `experiences/type`,
      memberTypes: `member/type`,
      serviceTypes: `service/type`,
    },
    estate: {
      all: `dashboard/details`,
      unaccepted: `fund/unaccepted/estates`,
      details: (id: number) => `fund/estate/${id}/show`,
      TYPES: `estate/type`,
      OPERATION_TYPE: `operation/type`,
      COMFORT: `comfort`,
    },
    requests: {
      allPreviews: `estate_request_preview`,
      orders: `fund/clickup/preview/requests`,
      allRatings: `estate_request_rate`,
      singleRating: (id: number) => `estate_request_rate/${id}`,
      fund_request_details: (id: number) => `fund_request_show/${id}`,
      financing_requests: `funding_request`,
    },
    users: {
      all: `admin/admins`,
      allPermissions: `admin/permissions`,
      add: `admin/create/admin`,
      update: `admin/update/admin`,
      addPermissions: `admin/add/permissions/admin`,
      singleRealator: (id: number) => `offices/${id}`,
    },
    reports: {
      all: `admin/all/reports`,
    },
    contacts: {
      all: `fund/contacts`,
    },
    plans: {
      all: `platform_plans`,
    },
    subscribes: {
      all: `subscription`,
    },
  },
  POST: {
    settings: {
      sendSms: `fund/send/sms`,
    },
    estate: {
      accept: `fund/accepte/estate`,
      delete: `fund/delete/estate`,
      UPDATE: (id: number) => `estate/${id}/update`,
    },
    requests: {
      assignEmp: `fund/clickup/emp/assigned`,
      AddFinanceStage: `fund/clickup/preview/requests/add/finance/stage`,
      AddContactStages: `fund/clickup/preview/requests/add/contact/stage`,
      AddFieldPreviewStage: `fund/clickup/preview/requests/add/field/preview/stage`,
      AddPreviewStage: `fund/clickup/preview/requests/add/preview/stage`,
    },
    users: {
      update_password: `admin/update_password`,
      update_realtor: (id: number) => `offices/${id}/update`,
    },
    auth: {
      login: `login`,
    },
    plans: {
      add: `platform_plans`,
      update: (id: number) => `platform_plans/${id}/update`,
      delete: (id: number) => `platform_plans/${id}/delete`,
    },
  },
};
