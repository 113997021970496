import {
  Action,
  combineReducers,
  configureStore,
  Middleware,
  PayloadAction,
  ThunkAction,
} from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import {
  ActionToString,
  ErrorToString,
  ReduxLoggerOptions,
  StateToString,
  createLogger,
} from "redux-logger";

import authReducer from "@pages/User/authSlice";
import appReducer from "./loading/appSlice";
import loadingReducer from "@store/loading/loadingSlice";
import rootSaga from "./rootSaga";
import estateReducer from "@pages/RealEstates/estateSlice";
import settingsReducer from "./settings/settingSlice";
import reportReducer from "@pages/Reports/reportSlice";
import userReducer from "@pages/UserManagement/userSlice";
import fundReducer from "@pages/Fund/fundSlice";
import inspectionReducer from "@pages/InspectionRequests/inspectionSlice";
import orderReducer from "@pages/FollowUpOnOrders/orderSlice";
import ratingsReducer from "@pages/RatingRequests/ratingSlice";
import contactReducer from "@pages/ContactUs/contactSlice";
import planReducer from "@pages/Plans/planSlice";
import subscribesReducer from "@pages/Subscribes/subcribeSlice";
import financing from "@pages/FinancingRequests/financingSlice";

const rootReducer = combineReducers({
  auth: authReducer,
  app: appReducer,
  loading: loadingReducer,
  estates: estateReducer,
  settings: settingsReducer,
  reports: reportReducer,
  users: userReducer,
  funds: fundReducer,
  inspection: inspectionReducer,
  orders: orderReducer,
  ratings: ratingsReducer,
  contacts: contactReducer,
  plans: planReducer,
  subscribes: subscribesReducer,
  financing,
});

const sagaMiddleware = createSagaMiddleware();

// Redux logger config
const logger: Middleware<ReduxLoggerOptions> = createLogger({
  level: "info",
  collapsed: true,
  duration: true,
  timestamp: true,
  logger: process.env.NODE_ENV === "development" ? console : undefined,
  colors: {
    title: (action: PayloadAction<ActionToString>) =>
      action.type === "error" ? "#F20404" : "#4CAF50",
    prevState: (state: StateToString) => "#9E9E9E",
    action: (action: ActionToString) => "#03A9F4",
    nextState: (state: StateToString) => "#4CAF50",
    error: (error: ErrorToString) => "#F20404",
  },
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({ thunk: false, serializableCheck: false }),
    sagaMiddleware,
    logger,
  ],
  devTools: process.env.NODE_ENV !== "production",
});

sagaMiddleware.run(rootSaga);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
