import { all, takeLatest } from "redux-saga/effects";

import { API_ENDPOINTS } from "@constants";
import { request } from "@utils";
import { estateActions } from "./estateSlice";

const {
  getEstates,
  getEstatesSuccess,
  getUnacceptedEstates,
  getUnacceptedEstatesSuccess,
  acceptEstate,
  deleteEstate,
  getEstateDetails,
  getEstateDetailsSuccess,
  updateEstate,
} = estateActions;

const getEstatesSaga = request({
  APIUrl: API_ENDPOINTS.GET.estate.all,
  requestType: "get",
  successFunction: getEstatesSuccess,
});

const getUnacceptedEstatesSaga = request({
  APIUrl: API_ENDPOINTS.GET.estate.unaccepted,
  requestType: "get",
  successFunction: getUnacceptedEstatesSuccess,
});

const acceptSaga = request({
  APIUrl: API_ENDPOINTS.POST.estate.accept,
  requestType: "post",
  showNotification: true,
});

const deleteEstateSaga = request({
  APIUrl: API_ENDPOINTS.POST.estate.delete,
  requestType: "post",
  showNotification: true,
});

const getEstateDetailsSaga = request({
  APIUrl: API_ENDPOINTS.GET.estate.details,
  requestType: "get",
  successFunction: getEstateDetailsSuccess,
});

const updateEstateSaga = request({
  APIUrl: API_ENDPOINTS.POST.estate.UPDATE,
  requestType: "post",
  showNotification: true,
});

export default function* estateSaga() {
  yield all([
    takeLatest(getEstates.type, getEstatesSaga),
    takeLatest(getUnacceptedEstates.type, getUnacceptedEstatesSaga),
    takeLatest(acceptEstate.type, acceptSaga),
    takeLatest(deleteEstate.type, deleteEstateSaga),
    takeLatest(getEstateDetails.type, getEstateDetailsSaga),
    takeLatest(updateEstate.type, updateEstateSaga),
  ]);
}
