import { FC } from "react";

interface IProps {
  title: string;
  children: React.ReactNode;
}

const Card: FC<IProps> = ({ title, children }) => {
  return (
    <div className="card">
      <div className="card-head">
        <h3 className="card-title">{title}</h3>
      </div>
      <div className="card-body pa-15">{children}</div>
    </div>
  );
};

export default Card;
