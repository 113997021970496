import { all, takeLatest } from "redux-saga/effects";

import { API_ENDPOINTS } from "@constants";
import { request } from "@utils";
import { inspectionActions } from "./inspectionSlice";

const { getInspections, getInspectionsSuccess } = inspectionActions;

// Get All Inspections
const getInspectionsSaga = request({
  APIUrl: API_ENDPOINTS.GET.requests.allPreviews,
  requestType: "get",
  successFunction: getInspectionsSuccess,
});

export default function* inspectionSaga() {
  yield all([takeLatest(getInspections.type, getInspectionsSaga)]);
}
