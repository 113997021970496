import { t } from "i18next";

interface ValidationDto {
  required?: boolean;
  pattern?: RegExp;
  message: string;
}

type RuleDto = Array<ValidationDto>;

export const requireRule: ValidationDto = {
  required: true,
  message: t("ThisFieldIsRequired!"),
};

export const colorRule: ValidationDto = {
  pattern: /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/,
  message: t("ColorIsInvalid!"),
};
