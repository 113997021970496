// Main Routes
export enum ROUTE {
  HOME = "/",
  DASHBOARD = "/dashboard",
}

// Real Estate Routes
export enum REAL_ESTATES {
  UNACCEPTED_ESTATES = "/unaccepted-estates",
  UNACCEPTED_ESTATES_DETAILS = "/unaccepted-estates/details/:id",
  REAL_ESTATES = "/real-estates",
  REAL_ESTATE_DETAILS = "/real-estates/details/:id",
  UPDATE_REAL_ESTATE = "/real-estates/update/:id",
}

// Requests Route
export enum REQUESTS {
  FUN_REQUESTS = "/fun-requests",
  FUND_REQUESTS_DETAILS = "/fun-requests/details/:id",
  INSPECTION_REQUEST = "/inspection-requests",
  INSPECTION_REQUEST_DETAILS = "/inspection-requests/details/:id",
  FOLLOW_UP_ON_ORDERS = "/follow-up-on-orders",
  MARKETING_REQUESTS = "/marketing-requests",
  DELETED_FUND_REQUESTS = "/deleted-fund-requests",
  RATINGS_REQUESTS = "/ratings-requests",
  RATINGS_REQUESTS_DETAILS = "/ratings-requests/details/:id",
  FINANCING_REQUESTS = "/financing-requests",
}

// Offers Route
export enum OFFERS {
  OFFERS = "/offers",
  FUND_OFFERS = "/offers/fund-offers",
  OFFERS_UNDER_PREVIEW_FUND = "/offers/offers-under-preview-fund",
  DELETE_FUND_ORDER_OFFERS = "/offers/delete-fund-order-offers",
  MARKETING_OFFERS = "/offers/marketing-offers",
}

// Financial Route
export enum FINANCIAL {
  FINANCIAL = "/financial",
  PLANS = "/financial/plans",
  ADD_PLAN = "/financial/plans/add",
  UPDATE_PLAN = "/financial/plans/update/:id",
  SUBSCRIBES = "/financial/subscribes",
}

// Users Route
export enum USERS {
  USER_MANAGEMENT = "/user-management",
  ADMIN = "/user-management/admin",
  AGENT = "/user-management/agent",
  REALTOR = "/user-management/realtor",
  UPDATE_REALTOR = "/user-management/realtor/update/:id",
}

// Contacts Route
export enum CONTACTS {
  CONTACTS = "/contacts",
}

// Reports Routes
export enum REPORTS {
  REPORTS = "/reports",
}

export enum NonAuthRoutes {
  LOGIN = "/login",
  SUPPORT = "/support",
  UNAUTHORIZED = "/unauthorized",
  NOT_FOUND = "*",
  LINKS = "/links",
}

export interface RouteDto {
  path: string;
  roles: string[];
  id: string;
  component: JSX.Element;
  title: string;
  breadcrumbParentId?: string;
}
export interface BasicMenuItem {
  title: string;
  icon?: JSX.Element;
  path: string;
  roles?: string[];
}
export type MenuItemChildren = (
  | BasicMenuItem
  | (BasicMenuItem & { children?: BasicMenuItem[] })
  | (BasicMenuItem & {
      children: BasicMenuItem[] & { children?: BasicMenuItem[] }[];
    })
)[];
export interface MenuItem extends BasicMenuItem {
  children?: MenuItemChildren;
}

type TArgs =
  | { path: ROUTE.DASHBOARD }
  | { path: USERS.USER_MANAGEMENT }
  | { path: REAL_ESTATES.REAL_ESTATES }
  | { path: REAL_ESTATES.UNACCEPTED_ESTATES }
  | { path: REAL_ESTATES.REAL_ESTATE_DETAILS; params: { id: number } }
  | { path: REAL_ESTATES.UNACCEPTED_ESTATES_DETAILS; params: { id: number } }
  | { path: REQUESTS.INSPECTION_REQUEST_DETAILS; params: { id: number } }
  | { path: REQUESTS.FUN_REQUESTS }
  | { path: REQUESTS.INSPECTION_REQUEST }
  | { path: REQUESTS.FOLLOW_UP_ON_ORDERS }
  | { path: OFFERS.OFFERS }
  | { path: OFFERS.FUND_OFFERS }
  | { path: OFFERS.OFFERS_UNDER_PREVIEW_FUND }
  | { path: OFFERS.DELETE_FUND_ORDER_OFFERS }
  | { path: OFFERS.MARKETING_OFFERS }
  | { path: USERS.ADMIN }
  | { path: USERS.AGENT }
  | { path: USERS.REALTOR }
  | { path: REPORTS.REPORTS }
  | { path: REQUESTS.MARKETING_REQUESTS }
  | { path: REQUESTS.DELETED_FUND_REQUESTS }
  | { path: REAL_ESTATES.UPDATE_REAL_ESTATE; params: { id: number } }
  | { path: USERS.UPDATE_REALTOR; params: { id: number } }
  | { path: REQUESTS.RATINGS_REQUESTS }
  | { path: REQUESTS.RATINGS_REQUESTS_DETAILS; params: { id: number } }
  | { path: CONTACTS.CONTACTS }
  | { path: REQUESTS.FUND_REQUESTS_DETAILS; params: { id: number } }
  | { path: FINANCIAL.FINANCIAL }
  | { path: FINANCIAL.PLANS }
  | { path: FINANCIAL.ADD_PLAN }
  | { path: FINANCIAL.UPDATE_PLAN; params: { id: number } }
  | { path: FINANCIAL.SUBSCRIBES };

type TArgsWithParams = Extract<TArgs, { path: string; params: any }>;

export function createPath(args: TArgs) {
  // Save some CPU power for routes without params
  if (args.hasOwnProperty("params") === false) return args.path;

  // Create a path by replacing params in the route definition
  return Object.entries((args as TArgsWithParams).params).reduce(
    (previousValue: string, [param, value]) =>
      previousValue.replace(`:${param}`, "" + value),
    args.path
  );
}
