import orderSaga from "@pages/FollowUpOnOrders/orderSaga";
import inspectionSaga from "@pages/InspectionRequests/inspectionSaga";
import estateSaga from "@pages/RealEstates/estateSaga";
import reportSaga from "@pages/Reports/reportSaga";
import authSaga from "@pages/User/authSaga";
import userSaga from "@pages/UserManagement/userSaga";
import { all } from "redux-saga/effects";
import settingSaga from "./settings/settingSaga";
import fundSaga from "@pages/Fund/fundSaga";
import ratingsSaga from "@pages/RatingRequests/ratingSaga";
import contactSaga from "@pages/ContactUs/contactSaga";
import planSaga from "@pages/Plans/planSaga";
import subscribesSaga from "@pages/Subscribes/subcribeSaga";
import financingSaga from "@pages/FinancingRequests/financingSaga";

export default function* rootSaga() {
  yield all([
    authSaga(),
    estateSaga(),
    settingSaga(),
    reportSaga(),
    userSaga(),
    inspectionSaga(),
    orderSaga(),
    fundSaga(),
    ratingsSaga(),
    contactSaga(),
    planSaga(),
    subscribesSaga(),
    financingSaga(),
  ]);
}
