import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RequestPayloadDto, SuccessPayload } from "@models";
import { FundOffersDto } from "@models/RealEstate";

interface initialStateDto {
  inspections?: SuccessPayload<FundOffersDto>;
}

const initialState: initialStateDto = {};

const inspectionSlice = createSlice({
  name: "inspection",
  initialState,
  reducers: {
    // All Inspections
    getInspections(
      _state: initialStateDto,
      _action: PayloadAction<RequestPayloadDto>
    ) {},
    getInspectionsSuccess(
      state: initialStateDto,
      action: PayloadAction<SuccessPayload<FundOffersDto>>
    ) {
      state.inspections = action.payload;
    },
  },
});

// Inspection Actions
export const inspectionActions = inspectionSlice.actions;

// Inspection Reducer
const inspectionReducer = inspectionSlice.reducer;

export default inspectionReducer;
