import Cookies, { CookieAttributes } from "js-cookie";

export const CookiesHelper = {
  get(key: string): any {
    const stored = Cookies.get(key);
    return stored == null ? undefined : stored;
  },
  set(key: string, value: string, options?: CookieAttributes) {
    if (value !== null && value !== undefined) {
      Cookies.set(key, value, { ...options });
    }
  },
  clearItem(key: string) {
    Cookies.remove(key);
  },
  clearItems(keys: string[]): void {
    keys.forEach((key) => this.clearItem(key));
  },
};
