import { all, takeLatest } from "redux-saga/effects";
import { API_ENDPOINTS } from "@constants";
import { request } from "@utils";
import { settingsActions } from "./settingSlice";

const {
  sendSms,
  estateType,
  estateTypeSuccess,
  getCities,
  getCitiesSuccess,
  getNeighborhoods,
  getNeighborhoodsSuccess,
  getState,
  getStateSuccess,
  getAreas,
  getAreasSuccess,
  getPrice,
  getPriceSuccess,
  getDashMap,
  getDashMapSuccess,
  getEmployees,
  getEmployeesSuccess,
  getComforts,
  getComfortsSuccess,
  getOperationTypes,
  getOperationTypesSuccess,
  getCourseTypes,
  getCourseTypesSuccess,
  getExperienceTypes,
  getExperienceTypesSuccess,
  getMemberTypes,
  getMemberTypesSuccess,
  getServiceTypes,
  getServiceTypesSuccess,
} = settingsActions;

const sendSmsSaga = request({
  requestType: "post",
  APIUrl: API_ENDPOINTS.POST.settings.sendSms,
  showNotification: true,
});

const estateTypeSaga = request({
  requestType: "get",
  APIUrl: API_ENDPOINTS.GET.estate.TYPES,
  successFunction: estateTypeSuccess,
});

const getStateSaga = request({
  requestType: "get",
  APIUrl: API_ENDPOINTS.GET.settings.state,
  successFunction: getStateSuccess,
});

const getCitiesSaga = request({
  requestType: "get",
  APIUrl: API_ENDPOINTS.GET.settings.cities,
  successFunction: getCitiesSuccess,
});

const getNeighborhoodsSaga = request({
  requestType: "get",
  APIUrl: API_ENDPOINTS.GET.settings.neighborhood,
  successFunction: getNeighborhoodsSuccess,
});

const getAreasSaga = request({
  requestType: "get",
  APIUrl: API_ENDPOINTS.GET.settings.area,
  successFunction: getAreasSuccess,
});

const getPriceSaga = request({
  requestType: "get",
  APIUrl: API_ENDPOINTS.GET.settings.price,
  successFunction: getPriceSuccess,
});

const getDashMapSaga = request({
  requestType: "get",
  APIUrl: API_ENDPOINTS.GET.settings.getDashMap,
  successFunction: getDashMapSuccess,
});

const getEmployeesSaga = request({
  requestType: "get",
  APIUrl: API_ENDPOINTS.GET.settings.employees,
  successFunction: getEmployeesSuccess,
});

const getComfortsSaga = request({
  requestType: "get",
  APIUrl: API_ENDPOINTS.GET.estate.COMFORT,
  successFunction: getComfortsSuccess,
});

const getOperationTypesSaga = request({
  requestType: "get",
  APIUrl: API_ENDPOINTS.GET.estate.OPERATION_TYPE,
  successFunction: getOperationTypesSuccess,
});

const getCourseTypesSaga = request({
  requestType: "get",
  APIUrl: API_ENDPOINTS.GET.settings.courseTypes,
  successFunction: getCourseTypesSuccess,
});

const getExperienceTypesSaga = request({
  requestType: "get",
  APIUrl: API_ENDPOINTS.GET.settings.experienceTypes,
  successFunction: getExperienceTypesSuccess,
});

const getMemberTypesSaga = request({
  requestType: "get",
  APIUrl: API_ENDPOINTS.GET.settings.memberTypes,
  successFunction: getMemberTypesSuccess,
});

const getServiceTypesSaga = request({
  requestType: "get",
  APIUrl: API_ENDPOINTS.GET.settings.serviceTypes,
  successFunction: getServiceTypesSuccess,
});

export default function* settingSaga() {
  yield all([
    takeLatest(sendSms.type, sendSmsSaga),
    takeLatest(estateType.type, estateTypeSaga),
    takeLatest(getState.type, getStateSaga),
    takeLatest(getCities.type, getCitiesSaga),
    takeLatest(getNeighborhoods.type, getNeighborhoodsSaga),
    takeLatest(getAreas.type, getAreasSaga),
    takeLatest(getPrice.type, getPriceSaga),
    takeLatest(getDashMap.type, getDashMapSaga),
    takeLatest(getEmployees.type, getEmployeesSaga),
    takeLatest(getComforts.type, getComfortsSaga),
    takeLatest(getOperationTypes.type, getOperationTypesSaga),
    takeLatest(getCourseTypes.type, getCourseTypesSaga),
    takeLatest(getExperienceTypes.type, getExperienceTypesSaga),
    takeLatest(getMemberTypes.type, getMemberTypesSaga),
    takeLatest(getServiceTypes.type, getServiceTypesSaga),
  ]);
}
