import { FC } from "react";
import Logo from "./Logo";
import { Layout } from "antd";
import { useTypedSelector } from "@hooks";
import Menu from "./Menu";
const { Sider } = Layout;

const SiderMenu: FC = () => {
  const { sidebarCollapsed } = useTypedSelector((state) => state.app);
  return (
    <Sider
      theme="light"
      trigger={null}
      collapsible
      collapsed={sidebarCollapsed}
      style={{
        overflow: "auto",
        height: "100vh",
        position: "fixed",
        insetInlineStart: 0,
        top: 0,
        bottom: 0,
      }}
    >
      <Logo collapsed={sidebarCollapsed} />
      <Menu />
    </Sider>
  );
};

export default SiderMenu;
