import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RequestPayloadDto, SuccessPayload } from "@models";
import { PlanDto } from "@pages/Plans/planSlice";

export interface SubscribeDto {
  id: number;
  plan_id: number;
  price: string;
  contract_number: number;
  duration: number;
  duration_type: string;
  status: string;
  start_time: string;
  end_time: string;
  payment_id: null;
  user_id: number;
  contract_number_used: number;
  time: string;
  plan: PlanDto;
}

interface initialStateDto {
  subscribes?: SubscribeDto[];
}

const initialState: initialStateDto = {};

const subscribeSlice = createSlice({
  name: "subscribes",
  initialState,
  reducers: {
    // Get All Subscribes
    allSubscribes(
      _state: initialStateDto,
      _action: PayloadAction<RequestPayloadDto>
    ) {},
    allSubscribesSuccess(
      state: initialStateDto,
      action: PayloadAction<SubscribeDto[]>
    ) {
      state.subscribes = action.payload;
    },
  },
});

// Subscribes Actions
export const subscribesActions = subscribeSlice.actions;

// Subscribes Reducer
const subscribesReducer = subscribeSlice.reducer;

export default subscribesReducer;
