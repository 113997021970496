import { all, takeLatest } from "redux-saga/effects";

import { API_ENDPOINTS } from "@constants";
import { request } from "@utils";
import { contactActions } from "./contactSlice";

const { getContacts, getContactsSuccess } = contactActions;

// Get All Contacts
const getContactsSaga = request({
  APIUrl: API_ENDPOINTS.GET.contacts.all,
  requestType: "get",
  successFunction: getContactsSuccess,
});

export default function* contactSaga() {
  yield all([takeLatest(getContacts.type, getContactsSaga)]);
}
