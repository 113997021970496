import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Collapse } from "antd";

import styles from "./styles.module.scss";
import { useAppParams } from "@hooks";

const { Panel } = Collapse;

const FilterationBox: FC<any> = ({ children }) => {
  const { t } = useTranslation();
  const { handleSearch, UrlParams } = useAppParams({});

  const [filterationBoxVisible, setFilterationBoxVisible] = useState(true);

  return (
    <Collapse
      defaultActiveKey={["FilterationBox"]}
      ghost
      onChange={(val) =>
        val.length > 0
          ? setFilterationBoxVisible(true)
          : setFilterationBoxVisible(false)
      }
    >
      <Panel
        className={styles.filterationBox}
        collapsible="header"
        header={
          filterationBoxVisible
            ? t("HideAdvancedFilters")
            : t("ShowAdvancedFilters")
        }
        key={"FilterationBox"}
        extra={
          <Button
            type="link"
            onClick={() =>
              handleSearch({
                deleteFields: Object.keys(UrlParams),
              })
            }
            disabled={Object.keys(UrlParams).length === 0}
          >
            {t("ClearFilters")}
          </Button>
        }
      >
        <p>{children}</p>
      </Panel>
    </Collapse>
  );
};
export default FilterationBox;
