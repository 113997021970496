import { lazy } from "react";
import {
  ROLES,
  ROUTE,
  REAL_ESTATES,
  REPORTS,
  USERS,
  REQUESTS,
  OFFERS,
  CONTACTS,
  FINANCIAL,
  RouteDto,
} from "@models";

const DashboardPage = lazy(() => import("@pages/Dashboard"));
const RealEstatePage = lazy(() => import("@pages/RealEstates/List"));
const UnacceptedEstatesPage = lazy(
  () => import("@pages/RealEstates/UnacceptedList")
);
const RealEstateDetailsPage = lazy(() => import("@pages/RealEstates/Details"));
const UpdateEstatePage = lazy(() => import("@pages/RealEstates/UpdateEstate"));

const MarketRequestsPage = lazy(() => import("@pages/Fund/MarketRequests"));

const FunRequestsPage = lazy(() => import("@pages/Fund/Requests/List"));
const FunRequestsDetailsPage = lazy(
  () => import("@pages/Fund/Requests/Details")
);
const FunOffersPage = lazy(() => import("@pages/Fund/Offers/List"));
const UnderReviewPage = lazy(() => import("@pages/Fund/UnderReview/List"));
const MarketingOffersPage = lazy(
  () => import("@pages/Fund/MarketingOffers/List")
);
const DeletingFundRequestsPage = lazy(
  () => import("@pages/Fund/DeletedFundRequests")
);
const FinancingRequestsPage = lazy(
  () => import("@pages/FinancingRequests/List")
);

const InspectionRequestsPage = lazy(
  () => import("@pages/InspectionRequests/List")
);

const RatingRequestsPage = lazy(() => import("@pages/RatingRequests/List"));
const RatingRequestsDetailsPage = lazy(
  () => import("@pages/RatingRequests/Details")
);

const FollowUpOnOrdersPage = lazy(() => import("@pages/FollowUpOnOrders/List"));

const AdminsPage = lazy(() => import("@pages/UserManagement/Admins"));
const UsersPage = lazy(() => import("@pages/UserManagement/Users"));
const RealtorsPage = lazy(() => import("@pages/UserManagement/Realtors"));
const UpdateRealtorsPage = lazy(
  () => import("@pages/UserManagement/UpdateRealtors")
);

const PlansPage = lazy(() => import("@pages/Plans/List"));
const AddPlanPage = lazy(() => import("@pages/Plans/CreateOrUpdatePlan"));

const SubscribesPage = lazy(() => import("@pages/Subscribes/List"));

const ReportsPage = lazy(() => import("@pages/Reports/List"));

const ContactsPage = lazy(() => import("@pages/ContactUs/List"));

export const routesList: RouteDto[] = [
  {
    path: ROUTE.DASHBOARD,
    roles: [ROLES.admin],
    component: <DashboardPage />,
    title: "Dashboard",
    id: "1",
  },
  {
    path: REAL_ESTATES.REAL_ESTATES,
    roles: [ROLES.admin],
    component: <RealEstatePage />,
    title: "RealEstates",
    id: "2",
    breadcrumbParentId: "1",
  },
  {
    path: REAL_ESTATES.REAL_ESTATE_DETAILS,
    roles: [ROLES.admin],
    component: <RealEstateDetailsPage />,
    title: "Details",
    id: "3",
    breadcrumbParentId: "2",
  },
  {
    path: REAL_ESTATES.UNACCEPTED_ESTATES,
    roles: [ROLES.admin],
    component: <UnacceptedEstatesPage />,
    title: "RealEstatesUnderReview",
    id: "4",
    breadcrumbParentId: "1",
  },
  {
    path: REPORTS.REPORTS,
    roles: [ROLES.admin],
    component: <ReportsPage />,
    title: "Reports",
    id: "5",
    breadcrumbParentId: "1",
  },
  {
    path: USERS.ADMIN,
    roles: [ROLES.admin],
    component: <AdminsPage />,
    title: "Admins",
    id: "6",
    breadcrumbParentId: "1",
  },
  {
    path: REQUESTS.FUN_REQUESTS,
    roles: [ROLES.admin],
    component: <FunRequestsPage />,
    title: "FundRequests",
    id: "7",
    breadcrumbParentId: "1",
  },
  {
    path: OFFERS.FUND_OFFERS,
    roles: [ROLES.admin],
    component: <FunOffersPage />,
    title: "FundOffers",
    id: "8",
    breadcrumbParentId: "1",
  },
  {
    path: REQUESTS.INSPECTION_REQUEST,
    roles: [ROLES.admin],
    component: <InspectionRequestsPage />,
    title: "InspectionRequests",
    id: "9",
    breadcrumbParentId: "1",
  },
  {
    path: REQUESTS.FOLLOW_UP_ON_ORDERS,
    roles: [ROLES.admin],
    component: <FollowUpOnOrdersPage />,
    title: "FollowUpOnOrders",
    id: "10",
    breadcrumbParentId: "1",
  },
  {
    path: OFFERS.OFFERS_UNDER_PREVIEW_FUND,
    roles: [ROLES.admin],
    component: <UnderReviewPage />,
    title: "UnderReview",
    id: "11",
    breadcrumbParentId: "1",
  },
  {
    path: OFFERS.MARKETING_OFFERS,
    roles: [ROLES.admin],
    component: <MarketingOffersPage />,
    title: "MarketingOffers",
    id: "12",
    breadcrumbParentId: "1",
  },
  {
    path: USERS.AGENT,
    roles: [ROLES.admin],
    component: <UsersPage />,
    title: "Users",
    id: "13",
    breadcrumbParentId: "1",
  },
  {
    path: USERS.REALTOR,
    roles: [ROLES.admin],
    component: <RealtorsPage />,
    title: "Realtors",
    id: "14",
    breadcrumbParentId: "1",
  },
  {
    path: REQUESTS.MARKETING_REQUESTS,
    roles: [ROLES.admin],
    component: <MarketRequestsPage />,
    title: "MarketRequests",
    id: "15",
    breadcrumbParentId: "1",
  },
  {
    path: REQUESTS.DELETED_FUND_REQUESTS,
    roles: [ROLES.admin],
    component: <DeletingFundRequestsPage />,
    title: "DeletedFundRequests",
    id: "16",
    breadcrumbParentId: "1",
  },
  {
    path: REAL_ESTATES.UPDATE_REAL_ESTATE,
    roles: [ROLES.admin],
    component: <UpdateEstatePage />,
    title: "UpdateRealEstate",
    id: "17",
    breadcrumbParentId: "2",
  },
  {
    path: USERS.UPDATE_REALTOR,
    roles: [ROLES.admin],
    component: <UpdateRealtorsPage />,
    title: "UpdateRealtor",
    id: "18",
    breadcrumbParentId: "14",
  },
  {
    path: REQUESTS.RATINGS_REQUESTS,
    roles: [ROLES.admin],
    component: <RatingRequestsPage />,
    title: "RatingRequests",
    id: "19",
    breadcrumbParentId: "1",
  },
  {
    path: REQUESTS.RATINGS_REQUESTS_DETAILS,
    roles: [ROLES.admin],
    component: <RatingRequestsDetailsPage />,
    title: "RatingRequestsDetails",
    id: "20",
    breadcrumbParentId: "19",
  },
  {
    path: CONTACTS.CONTACTS,
    roles: [ROLES.admin],
    component: <ContactsPage />,
    title: "ContactInfo",
    id: "21",
    breadcrumbParentId: "1",
  },
  {
    path: REQUESTS.FUND_REQUESTS_DETAILS,
    roles: [ROLES.admin],
    component: <FunRequestsDetailsPage />,
    title: "FundRequestsDetails",
    id: "22",
    breadcrumbParentId: "7",
  },
  {
    path: FINANCIAL.PLANS,
    roles: [ROLES.admin],
    component: <PlansPage />,
    title: "Plans",
    id: "23",
    breadcrumbParentId: "1",
  },
  {
    path: FINANCIAL.SUBSCRIBES,
    roles: [ROLES.admin],
    component: <SubscribesPage />,
    title: "Subscribes",
    id: "24",
    breadcrumbParentId: "1",
  },
  {
    path: REQUESTS.FINANCING_REQUESTS,
    roles: [ROLES.admin],
    component: <FinancingRequestsPage />,
    title: "FinancingRequests",
    id: "25",
    breadcrumbParentId: "1",
  },
];
