import { AxiosResponse } from "axios";
import { all, call, Effect, put, takeLatest } from "redux-saga/effects";
import { Modal } from "antd";
import { t } from "i18next";

import { request } from "@utils";
import { API_ENDPOINTS } from "@constants";
import axiosClient from "@constants/axiosClient";
import { RequestPayloadDto } from "@models";
import { loadingActions } from "@store/loading/loadingSlice";
import { orderActions } from "./orderSlice";

const { getAll, getAllSuccess, assignEmployee } = orderActions;

// Get All Orders
function* getAllOrders({ type, payload }: Effect<string, RequestPayloadDto>) {
  try {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: true })
    );

    const res: AxiosResponse<any> = yield call(
      axiosClient.get,
      API_ENDPOINTS.GET.requests.orders,
      {
        params: payload?.params,
      }
    );

    yield put(getAllSuccess({ ...res }));

    // @desc Callback function after Success
    payload?.callback?.();
  } catch (error) {
    Modal.error({
      title: `${t("SomethingWentWrong")}`,
      content: (error as Error)?.message,
    });
  } finally {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: false })
    );
  }
}

const assignEmployeeSaga = request({
  APIUrl: API_ENDPOINTS.POST.requests.assignEmp,
  requestType: "post",
  showNotification: true,
});

export default function* orderSaga() {
  yield all([
    takeLatest(getAll.type, getAllOrders),
    takeLatest(assignEmployee.type, assignEmployeeSaga),
  ]);
}
