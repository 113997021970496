/* eslint-disable no-sequences */
import axiosClient from "@constants/axiosClient";
import { RequestPayloadDto } from "@models";
import { loadingActions } from "@store/loading/loadingSlice";
import { Modal, notification } from "antd";
import { AxiosResponse } from "axios";
import { t } from "i18next";
import { Effect, call, delay, fork, put } from "redux-saga/effects";

export enum REQUEST_TYPE {
  GET = "get",
  POST = "post",
  PUT = "put",
  DELETE = "delete",
  PATCH = "patch",
}

type requestType = "get" | "post" | "put" | "delete" | "patch";

interface RequestParamDto {
  requestType: requestType;
  APIUrl: string | Function;
  successFunction?: Function;
  showNotification?: boolean;
  dataKey?: "data" | "result";
}

export const request = ({
  requestType,
  APIUrl = "",
  successFunction,
  showNotification = false,
  dataKey = "data",
}: RequestParamDto) => {
  // @desc Return Generator Function for Saga
  return function* requestSaga({
    type,
    payload,
  }: Effect<string, RequestPayloadDto>) {
    try {
      yield put(
        loadingActions.changeRequestLoading({ name: type, inProgress: true })
      );

      let url = "";
      let response: AxiosResponse = {} as AxiosResponse;

      // @desc - Check if APIUrlWithId is Function
      if (
        payload?.id !== undefined &&
        APIUrl &&
        APIUrl instanceof Function &&
        payload?.id !== null
      ) {
        url = APIUrl(payload?.id);
      } else {
        url = APIUrl as string;
      }

      yield delay(200);

      // @desc - Check Request Type
      if (
        requestType === REQUEST_TYPE.POST ||
        requestType === REQUEST_TYPE.PUT ||
        requestType === REQUEST_TYPE.PATCH
      ) {
        // Calling API POST Request & Send Data
        response = yield call(
          axiosClient[requestType] as any,
          url,
          payload.data,
          {
            params: payload?.params,
          }
        );
      } else if (
        requestType === REQUEST_TYPE.GET ||
        requestType === REQUEST_TYPE.DELETE
      ) {
        // Calling API GET Request
        response = yield call(axiosClient[requestType] as any, url, {
          params: payload?.params,
        });
      }

      if (
        successFunction &&
        typeof successFunction === "function" &&
        response
      ) {
        yield put(successFunction?.(response[dataKey as "data"]));
      }

      if (showNotification) {
        notification.success({
          message: `${t("OperationAccomplishedSuccessfully")}`,
        });
      }

      // @desc Callback function after Success
      payload?.callback?.();
    } catch (error) {
      Modal.error({
        title: `${t("SomethingWentWrong")}`,
        content: (error as Error)?.message,
      });
    } finally {
      payload?.finalCallback?.();
      yield put(
        loadingActions.changeRequestLoading({ name: type, inProgress: false })
      );
    }
  };
};
