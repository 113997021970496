import { FC } from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import { useTranslation } from "react-i18next";

import { createPath, ROUTE } from "@models";

import s from "./index.module.scss";
interface LogoProps {
  collapsed: boolean;
}

const Logo: FC<LogoProps> = ({ collapsed }) => {
  const { t } = useTranslation();
  return (
    <div className={s.sidebarLogoCont}>
      <Link
        to={createPath({
          path: ROUTE.DASHBOARD,
        })}
      >
        <img
          src={`${process.env.PUBLIC_URL}/assets/images/${
            collapsed ? "small-logo.png" : "logo-white.png"
          }`}
          className={cn(s.sidebarLogo, {
            [s.sidebarLogoColl]: collapsed,
          })}
          alt={t("Aqarz")}
          title={t("Aqarz")}
        />
      </Link>
    </div>
  );
};

export default Logo;
