import { NavigateFunction } from "react-router-dom";
import { RootState } from "@store";
import { User } from "@models/user";
import { NonAuthRoutes, RequestPayloadDto } from "@models";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { CookiesHelper } from "@utils";

export interface LoginPayload {
  email: string;
  password: string;
}
export interface LogoutPayload {
  navigate: NavigateFunction;
}
export interface AuthState {
  isLoggedIn: boolean;
  logging?: boolean;
  currentUser?: User;
}

const initialState: AuthState = {
  isLoggedIn: false,
  logging: false,
  currentUser: !!CookiesHelper.get("user")
    ? JSON.parse(CookiesHelper.get("user")!)
    : undefined,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login(_state: AuthState, _action: PayloadAction<RequestPayloadDto>) {},
    loginSuccess(state: AuthState, action: PayloadAction<any>) {
      state.isLoggedIn = true;
      state.currentUser = action.payload;
    },
    loginFailed(state, action: PayloadAction<string>) {
      state.logging = false;
    },
    logout(state, action: PayloadAction<LogoutPayload>) {
      state.isLoggedIn = false;
      state.currentUser = undefined;
      action.payload.navigate(NonAuthRoutes.LOGIN);
    },
  },
});

// Actions
export const authActions = authSlice.actions;

// Selectors
export const selectIsLoggedIn = (state: RootState) => state.auth.isLoggedIn;
export const selectIsLogging = (state: RootState) => state.auth.logging;

// Reducer
const authReducer = authSlice.reducer;
export default authReducer;
