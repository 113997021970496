import { FC } from "react";
import { useNavigate } from "react-router-dom";
import {
  DownOutlined,
  LogoutOutlined,
  GlobalOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Menu, Dropdown, Modal, Layout, Avatar, Space } from "antd";
import { useTranslation } from "react-i18next";

import { useTypedSelector, useAppDispatch } from "@hooks";
import { authActions } from "@pages/User/authSlice";

import Hamburger from "../Hamburger";

import cn from "classnames";
import s from "./index.module.scss";
import LangDropDown from "../Language";

const { Header } = Layout;

const LayoutHeader: FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { sidebarCollapsed } = useTypedSelector((state) => state.app);
  const { currentUser } = useTypedSelector((state) => state.auth);

  // handle logout
  const handleLogout = () => {
    Modal.confirm({
      title: t("logout"),
      content: t("areYouSureToLogout"),
      okText: t("yes"),
      cancelText: t("cancel"),
      onOk: () => {
        dispatch(authActions.logout({ navigate }));
      },
    });
  };

  // user menu
  const UserMenu: FC = () => (
    <Menu>
      <Menu.Item key="logout" onClick={handleLogout}>
        <LogoutOutlined className={s.userMenuIcon} /> {t("logout")}
      </Menu.Item>
    </Menu>
  );

  return (
    <Header
      className={cn(s.header, {
        [s.headerLayoutColl]: sidebarCollapsed,
        [s.headerLayoutNotColl]: !sidebarCollapsed,
      })}
    >
      <Hamburger />
      <div className={s.rightMenu}>
        <div className="dropdown-wrap">
          <Space align="center">
            <Dropdown overlay={<UserMenu />}>
              <div className={s.userWrap}>
                <Avatar
                  shape="square"
                  size={30}
                  src={`${process.env.PUBLIC_URL}/assets/images/user.pn`}
                  icon={<UserOutlined />}
                />
                <span className={s.userName}>
                  {currentUser && currentUser?.role && t(currentUser?.role)}
                </span>
                <DownOutlined />
              </div>
            </Dropdown>
            <LangDropDown />
          </Space>
        </div>
      </div>
    </Header>
  );
};

export default LayoutHeader;
