import {
  AdminDto,
  PermissionsDto,
  RequestPayloadDto,
  SuccessPayload,
  User,
} from "@models";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface initialStateDto {
  admins?: SuccessPayload<AdminDto>;
  permissions?: PermissionsDto[];
  users?: SuccessPayload<User>;
  user?: User;
}

const initialState: initialStateDto = {};

const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    allAdmins(
      _state: initialStateDto,
      _action: PayloadAction<RequestPayloadDto>
    ) {},
    allAdminsSuccess(
      state: initialStateDto,
      action: PayloadAction<SuccessPayload<AdminDto>>
    ) {
      state.admins = action.payload;
    },
    // All Permissions
    allPermissions() {},
    allPermissionsSuccess(
      state: initialStateDto,
      action: PayloadAction<Array<PermissionsDto>>
    ) {
      state.permissions = action.payload;
    },
    // Add User Reducer
    addUser(
      _state: initialStateDto,
      _action: PayloadAction<RequestPayloadDto>
    ) {},
    // Update User Reducer
    updateUser(
      _state: initialStateDto,
      _action: PayloadAction<RequestPayloadDto>
    ) {},
    // Add Permissions
    addUserPermissions(
      _state: initialStateDto,
      _action: PayloadAction<RequestPayloadDto>
    ) {},
    // Change State After Add Or Update User
    changeUserState(state: initialStateDto, action: PayloadAction<AdminDto>) {
      state.admins?.data?.push(action.payload);
    },

    // Get All Users
    allUsers(_state, _action: PayloadAction<RequestPayloadDto>) {},
    allUsersSuccess(state, action: PayloadAction<any>) {
      state.users = action.payload.users || action.payload.offices;
    },

    // Update Admin Password
    updateAdminPassword(
      _state: initialStateDto,
      _action: PayloadAction<RequestPayloadDto>
    ) {},

    // Get Single Realtor
    getSingleRealtor(
      _state: initialStateDto,
      _action: PayloadAction<RequestPayloadDto>
    ) {},
    getSingleRealtorSuccess(
      state: initialStateDto,
      action: PayloadAction<User>
    ) {
      state.user = action.payload;
    },

    // Update Realtor
    updateRealtor(
      _state: initialStateDto,
      _action: PayloadAction<RequestPayloadDto>
    ) {},
  },
});

// User Actions
export const userActions = userSlice.actions;

// User Reducer
const userReducer = userSlice.reducer;

export default userReducer;
