import i18n from "i18next";
import moment from "moment";
import { Localization } from "@utils";

export const TimingHelper = {
  defaultDateTimeFormat: "D/MM/YYYY - h:mm A",
  defaultTimeFormat: "hh:mm A",
  defaultDateFormat: "DD-MM-YYYY",
  backendDateFormat: "YYYY-MM-DD",

  initTiming: () =>
    moment.updateLocale(i18n.language, Localization.getMomentLocals()),
};
