import { RequestPayloadDto, SuccessPayload } from "@models";
import { FundRequestDto, RealEstateDto } from "@models/RealEstate";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface initialStateDto {
  estates?: {
    estate?: SuccessPayload<RealEstateDto>;
    fund_request?: SuccessPayload<FundRequestDto>;
    app_request?: number;
    fund_request_deleted?: number;
    fund_request_offer?: number;
    fund_request_offer_deleted?: number;
    fund_review_request_offer?: number;
    offices?: number;
    request_offer?: number;
    unaccepted_estates?: number;
    users?: number;
  };
  unacceptedEstates?: any;
  realEstate?: RealEstateDto;
}

const initialState: initialStateDto = {
  realEstate: undefined,
};

const estateSlice = createSlice({
  name: "Estates",
  initialState,
  reducers: {
    // All Estates
    getEstates(
      _state: initialStateDto,
      _action: PayloadAction<RequestPayloadDto>
    ) {},
    getEstatesSuccess(state: initialStateDto, action: PayloadAction<any>) {
      state.estates = action.payload;
    },
    // All Unaccepted Estates
    getUnacceptedEstates(
      _state: initialStateDto,
      _action: PayloadAction<RequestPayloadDto>
    ) {},
    getUnacceptedEstatesSuccess(
      state: initialStateDto,
      action: PayloadAction<any>
    ) {
      state.unacceptedEstates = action.payload;
    },
    // Accept New Estate
    acceptEstate(
      _state: initialStateDto,
      _action: PayloadAction<RequestPayloadDto>
    ) {},
    // @desc Remove Accepted Estate From Table After Accept Without Request
    removeEstateAfterAcceptOrDecline(
      state: initialStateDto,
      action: PayloadAction<any>
    ) {
      state.unacceptedEstates.data = action.payload;
    },
    // Delete Estate Reducer
    deleteEstate(
      _state: initialStateDto,
      _action: PayloadAction<RequestPayloadDto>
    ) {},
    // Get Estate Details
    getEstateDetails(
      _state: initialStateDto,
      _action: PayloadAction<RequestPayloadDto>
    ) {},
    getEstateDetailsSuccess(
      state: initialStateDto,
      action: PayloadAction<RealEstateDto>
    ) {
      state.realEstate = action.payload;
    },

    // Update Estate
    updateEstate(
      _state: initialStateDto,
      _action: PayloadAction<RequestPayloadDto>
    ) {},

    // @desc - reset estate details
    resetEstateDetails(state: initialStateDto) {
      state.realEstate = undefined;
    },
  },
});

// Estates Actions
export const estateActions = estateSlice.actions;

// Estate Reducer
const estateReducer = estateSlice.reducer;

export default estateReducer;
