import { createSlice } from '@reduxjs/toolkit';

export interface AppState {
  sidebarCollapsed: boolean;
}

const initialState: AppState = {
  sidebarCollapsed: false,
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    toggleSideBar(state) {
      state.sidebarCollapsed = !state.sidebarCollapsed;
    },
  },
});

// Actions
export const appActions = appSlice.actions;

// Reducer
const appReducer = appSlice.reducer;
export default appReducer;
