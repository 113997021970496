import { MenuItem } from "@models";
import { t } from "i18next";
export const capitalizeString = (str: string): string => {
  if (!str) return "";

  return `${str[0].toUpperCase()}${str.slice(1)}`;
};

//Find the menuItem with the attribute value from the MenuList based on the attribute value
export function getMenuItemInMenuListByProperty(
  menuList: Array<MenuItem>,
  key: string,
  value: string
) {
  let stack: Array<any> = [];
  stack = stack.concat(menuList);
  let res;
  while (stack.length) {
    let cur = stack.shift();
    if (cur.children && cur.children.length > 0) {
      stack = cur.children.concat(stack);
    }
    if (value === cur[key]) {
      res = cur;
    }
  }
  return res;
}

// Translate the key to the value in the object
export function translateKeyToValue(key?: string) {
  switch (key) {
    case "new":
      return t("New");
    case "residential":
      return t("Residential");
    case "north":
      return t("North");
    case "south":
      return t("South");
    case "east":
      return t("East");
    case "west":
      return t("West");
    case "daily":
      return t("Daily");
    case "weekly":
      return t("Weekly");
    case "monthly":
      return t("Monthly");
    case "yearly":
      return t("Yearly");
    case "hourly":
      return t("Hourly");
    case "closed":
      return t("Closed");
    case "completed":
      return t("Completed");
    case "pending":
      return t("Pending");
    default:
      return key;
  }
}

export const formLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 6 },
    lg: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 24, offset: 0 },
  },
};
