import { RequestPayloadDto, SuccessPayload } from "@models";
import { OrderDto } from "@models/order";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface initialStateDto {
  orders?: SuccessPayload<OrderDto>;
}

const initialState: initialStateDto = {};

const orderSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    getAll(
      _state: initialStateDto,
      _action: PayloadAction<RequestPayloadDto>
    ) {},
    getAllSuccess(state: initialStateDto, action: PayloadAction<any>) {
      state.orders = {
        data: action.payload.result.data,
        links: action.payload.result.links,
        ...action.payload.result.meta,
      };
    },
    assignEmployee(
      _state: initialStateDto,
      _action: PayloadAction<RequestPayloadDto>
    ) {},
  },
});

// Order Actions
export const orderActions = orderSlice.actions;

// Order Reducer
const orderReducer = orderSlice.reducer;

export default orderReducer;
