import { FC, useEffect, useRef } from "react";
import { Input, Select, Row, Col, Typography, DatePicker } from "antd";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

import { TimingHelper } from "@utils";

import s from "./index.module.scss";

const { Option } = Select;

interface FilterDropdownProps {
  handleSearch: (prop: string) => void;
  handleReset: (name: string) => void;
  handleChange: (name: string, val: any, type: "search" | "filter") => void;
  value: any;
  label: string;
  name: string;
  placeHolder?: string;
  visible: boolean;
  options?: any[];
  optionValueName?: string;
  optionName?: string;
  inputType?: "single" | "multi" | "date";
  type: "search" | "filter";
  disabled?: boolean;
}

const FilterDropdown: FC<FilterDropdownProps> = ({
  handleSearch,
  handleReset,
  handleChange,
  value,
  label,
  name,
  placeHolder = "",
  visible,
  options = [],
  optionValueName,
  optionName,
  inputType = "single",
  type = "search",
  disabled = false,
}) => {
  const { t } = useTranslation();
  const inputRef = useRef<any>(null);
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [visible]);

  if (inputType === "multi" && (!options || !optionName || !optionValueName)) {
    throw Error(
      "The input type is multi and the  options or option Name or option value name not passed as props"
    );
  }

  return (
    <div className={s.filterDrop}>
      <Row gutter={30}>
        <Col lg={24} style={{ marginBlockEnd: 10 }}>
          <Typography.Text className={s.label}>{label}</Typography.Text>
          {inputType === "single" && (
            <Input
              disabled={disabled}
              ref={inputRef}
              placeholder={placeHolder}
              value={value}
              onChange={(e) => {
                if (e.target.value) {
                  return handleChange(name, e.target.value, type);
                }
                return handleReset(name);
              }}
              onPressEnter={() => handleSearch(name)}
              allowClear
            />
          )}

          {inputType === "multi" && (
            <Select
              disabled={disabled}
              allowClear
              value={+value || undefined}
              placeholder={placeHolder}
              onChange={(val) => handleChange(name, val, type)}
              // onSelect={() => setTimeout(() => handleSearch(name), 500)}
              onClear={() => handleReset(name)}
              className={s.select}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option!.children as unknown as string).includes(input)
              }
              filterSort={(optionA, optionB) =>
                (optionA!.children as unknown as string)
                  .toLowerCase()
                  .localeCompare(
                    (optionB!.children as unknown as string).toLowerCase()
                  )
              }
            >
              {options?.map((option) => (
                <Option
                  key={option[optionValueName as string]}
                  value={option[optionValueName as string]}
                >
                  {option[optionName as string]}
                </Option>
              ))}
            </Select>
          )}

          {inputType === "date" && (
            <DatePicker
              style={{ width: "100%" }}
              disabled={disabled}
              allowClear
              onChange={(val) => {
                if (dayjs(val).isValid()) {
                  return handleChange(
                    name,
                    dayjs(val).format(TimingHelper.backendDateFormat),
                    type
                  );
                }
                return handleReset(name);
              }}
            />
          )}
        </Col>
      </Row>
    </div>
  );
};

export default FilterDropdown;
