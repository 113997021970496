import { all, takeLatest } from "redux-saga/effects";

import { API_ENDPOINTS } from "@constants";
import { request } from "@utils";
import { planActions } from "./planSlice";

const { allPlans, allPlansSuccess, addPlan, deletePlan, updatePlan } =
  planActions;

// Get All Plans
const getAllPlans = request({
  APIUrl: API_ENDPOINTS.GET.plans.all,
  requestType: "get",
  successFunction: allPlansSuccess,
});

// Add Plan
const addNewPlan = request({
  APIUrl: API_ENDPOINTS.POST.plans.add,
  requestType: "post",
});

// Update Plan
const updateExistingPlan = request({
  APIUrl: API_ENDPOINTS.POST.plans.update,
  requestType: "post",
});

// Delete Plan
const deleteExistingPlan = request({
  APIUrl: API_ENDPOINTS.POST.plans.delete,
  requestType: "post",
});

export default function* planSaga() {
  yield all([
    takeLatest(allPlans.type, getAllPlans),
    takeLatest(addPlan.type, addNewPlan),
    takeLatest(updatePlan.type, updateExistingPlan),
    takeLatest(deletePlan.type, deleteExistingPlan),
  ]);
}
