import { RequestPayloadDto } from "@models";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface initialStateDto {
  reports?: any;
}

const initialState: initialStateDto = {};

const reportSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    // Get All Reports
    allReports(
      _state: initialStateDto,
      _action: PayloadAction<RequestPayloadDto>
    ) {},
    allReportsSuccess(state: initialStateDto, action: PayloadAction<any>) {
      state.reports = action.payload;
    },
  },
});

// Reports Actions
export const reportActions = reportSlice.actions;

// Reports Reducer
const reportReducer = reportSlice.reducer;

export default reportReducer;
