import { FC, useState } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Switch,
  Tooltip,
} from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { objectToFormData } from "@utils";
import { settingsActions } from "@store/settings/settingSlice";
import { useTypedSelector } from "@hooks";
import { RequestsEnum } from "@models";
import { getSingleRequest } from "@store/loading/loadingSlice";
import { estateActions } from "@pages/RealEstates/estateSlice";

import * as RULES from "@constants/validation";

import styles from "./styles.module.scss";

interface SendSmsProps {
  icon?: any;
  TooltipText?: string;
  id: number;
  disabled?: boolean;
  btnText?: string;
}

const SendSms: FC<SendSmsProps> = ({
  icon,
  TooltipText,
  id,
  disabled,
  btnText,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const sendSms = useTypedSelector((state) =>
    getSingleRequest(state, RequestsEnum.sendSms)
  );
  const { unacceptedEstates } = useTypedSelector((state) => state.estates);

  const showModal = () => {
    setIsModalVisible(true);
  };

  // @desc Submit Modal
  const handleOk = () => {
    const { send_notification, send_sms, ...otherData } = form.getFieldsValue();
    let values = {
      ...otherData,
      estate_id: id,
      send_notification: +!!send_notification,
      send_sms: +!!send_sms,
    };

    if (values.reason === "other") {
      values.reason = values.other_reason;
    }

    delete values.other_reason;

    dispatch(
      settingsActions.sendSms({
        data: objectToFormData(values),
        callback: () => {
          const newObj = Object.values(unacceptedEstates?.data).filter(
            (item: any) => item.id !== id
          );

          dispatch(estateActions.removeEstateAfterAcceptOrDecline(newObj));

          setIsModalVisible(false);
        },
      })
    );
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  return (
    <>
      {!!TooltipText ? (
        <Tooltip placement="top" title={TooltipText}>
          <Button
            type="link"
            onClick={showModal}
            icon={icon}
            className={styles.btn}
            disabled={disabled}
          />
        </Tooltip>
      ) : (
        <Button
          onClick={showModal}
          icon={icon}
          className={"rounded-5"}
          disabled={disabled}
          type="primary"
          danger
        >
          {btnText}
        </Button>
      )}
      <Modal
        title={t("SendSms")}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{ loading: sendSms.inProgress }}
        className="modal"
      >
        <Form
          labelCol={{ span: 7 }}
          wrapperCol={{ span: 17 }}
          form={form}
          onFinish={handleOk}
        >
          <Row gutter={30} className="mb-20">
            <Col lg={24}>
              <Form.Item
                labelCol={{ md: 9 }}
                label={t("SendNotificationToApplication")}
                name="send_notification"
                rules={[RULES.requireRule]}
              >
                <Switch />
              </Form.Item>
            </Col>
            <Col lg={24}>
              <Form.Item
                labelCol={{ md: 9 }}
                label={t("SendMobileSms")}
                name="send_sms"
                rules={[RULES.requireRule]}
              >
                <Switch />
              </Form.Item>
            </Col>
            <Col lg={24}>
              <Form.Item
                labelCol={{ md: 9 }}
                label={t("Reason")}
                name="reason"
                rules={[RULES.requireRule]}
              >
                <Select placeholder={t("Choose...")}>
                  <Select.Option value="بيانات العرض غير صحيحة أو غير مكتملة">
                    {t("TheOfferDataIsIncorrectOrIncomplete")}
                  </Select.Option>
                  <Select.Option value="صور غير ملائمة">
                    {t("InappropriatePhotos")}
                  </Select.Option>
                  <Select.Option value="بيانات المالك أو المعلن غير مكتملة">
                    {t("IncompleteOwnerOrAdvertiserData")}
                  </Select.Option>
                  <Select.Option value="other">{t("Other")}</Select.Option>
                </Select>
              </Form.Item>

              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.reason !== currentValues.reason
                }
              >
                {({ getFieldValue }) => {
                  return getFieldValue("reason") === "other" ? (
                    <Form.Item
                      labelCol={{ md: 9 }}
                      label={t("OtherReason")}
                      name="other_reason"
                      rules={[RULES.requireRule]}
                    >
                      <Input.TextArea
                        placeholder={t("OtherReason")}
                        autoSize={{ minRows: 3, maxRows: 5 }}
                      />
                    </Form.Item>
                  ) : null;
                }}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default SendSms;
