import {
  HomeOutlined,
  FileAddOutlined,
  LockOutlined,
  ClusterOutlined,
} from "@ant-design/icons";
import {
  CONTACTS,
  FINANCIAL,
  MenuItem,
  OFFERS,
  REAL_ESTATES,
  REPORTS,
  REQUESTS,
  ROUTE,
  USERS,
} from "@models";

const menuList: MenuItem[] = [
  {
    title: "Dashboard",
    path: ROUTE.DASHBOARD,
    icon: <HomeOutlined />,
    roles: ["admin", "editor", "guest"],
  },
  {
    title: "RealEstates",
    path: REAL_ESTATES.REAL_ESTATES,
    icon: <LockOutlined />,
    children: [
      {
        title: "RealEstatesToReview",
        path: REAL_ESTATES.UNACCEPTED_ESTATES,
        roles: ["admin"],
      },
      {
        title: "RealEstates",
        path: REAL_ESTATES.REAL_ESTATES,
        roles: ["admin"],
      },
    ],
  },
  {
    title: "Orders",
    path: REQUESTS.FUN_REQUESTS,
    icon: <FileAddOutlined />,
    roles: ["admin", "editor", "guest"],
    children: [
      {
        title: "FinancingRequests",
        path: REQUESTS.FINANCING_REQUESTS,
        roles: ["admin"],
      },
      {
        title: "FundRequests",
        path: REQUESTS.FUN_REQUESTS,
        roles: ["admin"],
      },
      {
        title: "InspectionRequests",
        path: REQUESTS.INSPECTION_REQUEST,
        roles: ["admin"],
      },
      {
        title: "RatingRequests",
        path: REQUESTS.RATINGS_REQUESTS,
        roles: ["admin"],
      },
      {
        title: "FollowUpOnOrders",
        path: REQUESTS.FOLLOW_UP_ON_ORDERS,
        roles: ["admin"],
      },
      {
        title: "MarketRequests",
        path: REQUESTS.MARKETING_REQUESTS,
        roles: ["admin"],
      },
      {
        title: "DeletedFundRequests",
        path: REQUESTS.DELETED_FUND_REQUESTS,
        roles: ["admin"],
      },
    ],
  },
  {
    title: "Offers",
    path: OFFERS.OFFERS,
    icon: <ClusterOutlined />,
    roles: ["admin"],
    children: [
      {
        title: "FundOffers",
        path: OFFERS.FUND_OFFERS,
        roles: ["admin"],
      },
      {
        title: "OffersUnderPreviewFund",
        path: OFFERS.OFFERS_UNDER_PREVIEW_FUND,
        roles: ["admin"],
      },
      {
        title: "DeletedFundOrderOffers",
        path: OFFERS.DELETE_FUND_ORDER_OFFERS,
        roles: [""],
      },
      {
        title: "MarketOffers",
        path: OFFERS.MARKETING_OFFERS,
        roles: ["admin"],
      },
    ],
  },
  {
    title: "UserManagement",
    path: USERS.USER_MANAGEMENT,
    icon: <ClusterOutlined />,
    roles: ["admin"],
    children: [
      {
        title: "Admins",
        path: USERS.ADMIN,
        roles: ["admin"],
      },
      {
        title: "Users",
        path: USERS.AGENT,
        roles: ["admin"],
      },
      {
        title: "Realtors",
        path: USERS.REALTOR,
        roles: ["admin"],
      },
    ],
  },
  {
    title: "FinancialStatements",
    path: FINANCIAL.FINANCIAL,
    icon: <ClusterOutlined />,
    roles: ["admin"],
    children: [
      {
        title: "Plans",
        path: FINANCIAL.PLANS,
        roles: ["admin"],
      },
      {
        title: "Subscribes",
        path: FINANCIAL.SUBSCRIBES,
        roles: ["admin"],
      },
    ],
  },
  {
    title: "RentInstallment",
    path: "/rentInstallment",
    icon: <ClusterOutlined />,
    roles: [],
  },
  {
    title: "Reports",
    path: REPORTS.REPORTS,
    icon: <ClusterOutlined />,
    roles: ["admin", "editor"],
  },
  {
    title: "ContactInfo",
    path: CONTACTS.CONTACTS,
    icon: <ClusterOutlined />,
    roles: ["admin"],
  },
];
export default menuList;
