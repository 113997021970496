import { FC } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { CardProps } from "antd/lib/card";
import { Card } from "antd";

interface IProps extends CardProps {
  title: string;
  children: React.ReactNode;
}

const Page: FC<IProps> = ({ title, children, ...rest }) => {
  const { t } = useTranslation();

  return (
    <Card {...rest} title={t(title)}>
      <Helmet title={t(title)} />

      {children}
    </Card>
  );
};

export default Page;
