import { Modal } from "antd";

interface ConfirmParams {
  Progress?: boolean;
  onAccept: any;
  TitleText: string;
  contentText: string;
}

export const showConfirm = ({
  Progress,
  onAccept,
  TitleText,
  contentText,
}: ConfirmParams) => {
  Modal.confirm({
    title: TitleText,
    content: contentText,
    okButtonProps: { loading: Progress },
    cancelButtonProps: { style: { marginInlineEnd: "10px" } },
    async onOk() {
      return await onAccept();
    },
    onCancel() {
      console.log("Cancel");
    },
  });
};
