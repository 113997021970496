import { all, takeLatest } from "redux-saga/effects";

import { API_ENDPOINTS } from "@constants";
import { request } from "@utils";
import { subscribesActions } from "./subcribeSlice";

const { allSubscribes, allSubscribesSuccess } = subscribesActions;

// Get All Subscribes
const getAllSubscribes = request({
  APIUrl: API_ENDPOINTS.GET.subscribes.all,
  requestType: "get",
  successFunction: allSubscribesSuccess,
});

export default function* subscribesSaga() {
  yield all([takeLatest(allSubscribes.type, getAllSubscribes)]);
}
