import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Tag } from "antd";

interface IProps {
  status: string;
}

const EstateStatus: FC<IProps> = ({ status }) => {
  const { t } = useTranslation();

  switch (status) {
    case "under_edit_publish":
      return <Tag color="blue">{t("Pending")}</Tag>;
    case "closed":
      return <Tag color="blue">{t("Inactive")}</Tag>;
    case "new":
      return <Tag color="blue">{t("Review")}</Tag>;
    case "completed":
    case "complete":
      return <Tag color="success">{t("Active")}</Tag>;
    case "expired":
      return <Tag color="error">{t("NeedUpdate")}</Tag>;
    default:
      return <Tag color="green">{status}</Tag>;
  }
};

export default EstateStatus;
