import { FC } from "react";
import { Layout } from "antd";
import Sider from "./Sider";
import Header from "./Header";
import BreadCrumb from "./BreadCrumb";
import Content from "./Content";
import { useTypedSelector } from "@hooks";

const Main: FC = () => {
  const { sidebarCollapsed } = useTypedSelector((state) => state.app);

  return (
    <Layout style={{ minHeight: "100vh" }} hasSider>
      <Sider />
      <Layout
        className="site-layout"
        style={{
          marginInlineStart: sidebarCollapsed ? 80 : 200,
        }}
      >
        <Header />
        <BreadCrumb />
        <Content />
      </Layout>
    </Layout>
  );
};

export default Main;
