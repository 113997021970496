import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RequestPayloadDto, SuccessPayload } from "@models";

export interface PlanDto {
  id: number;
  name_ar: string;
  name_en: string;
  contract_number: number;
  duration: number;
  price: number;
  status: string;
  created_at: string;
  updated_at: string;
  deleted_at: null;
  duration_type: string;
  color: string;
  name: string;
}

interface initialStateDto {
  plans?: PlanDto[];
}

const initialState: initialStateDto = {};

const planSlice = createSlice({
  name: "plans",
  initialState,
  reducers: {
    // Get All Plans
    allPlans(
      _state: initialStateDto,
      _action: PayloadAction<RequestPayloadDto>
    ) {},
    allPlansSuccess(state: initialStateDto, action: PayloadAction<PlanDto[]>) {
      state.plans = action.payload;
    },

    // Add Plan
    addPlan(
      _state: initialStateDto,
      _action: PayloadAction<RequestPayloadDto>
    ) {},

    // Update Plan
    updatePlan(
      _state: initialStateDto,
      _action: PayloadAction<RequestPayloadDto>
    ) {},

    // Delete Plan
    deletePlan(
      _state: initialStateDto,
      _action: PayloadAction<RequestPayloadDto>
    ) {},
  },
});

// plans Actions
export const planActions = planSlice.actions;

// plans Reducer
const planReducer = planSlice.reducer;

export default planReducer;
